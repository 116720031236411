import axios, { AxiosError, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { OnSendPostProcessContextView, ApiResponseView, AppInfo, ConfirmInfo, EmailInfo, LoginModel, OutlookAttachment, PlatformConfig } from "./components/Backend/BackendTypes";
import configJson from "./config.json";
import { safeSendLogging } from "./lib/Logger";
import { Modules } from "./LogConfiguration";

const logger = safeSendLogging.getLogger(Modules.SafeSend_App);

export interface ApiResponse<T> {
	statusCode: number,
	content: T | null,
	errorMessage: string
	errorContent: any,
	isSuccessStatusCode: boolean
}

axiosRetry(axios, {
	retries: configJson.retryOptions.retries,
	retryDelay: (retryCount: number, error: AxiosError<any>) => {
		logger.error(`Retry attempt: (${retryCount} of ${configJson.retryOptions.retries}), Error Found:`, error);
		return configJson.retryOptions.retryDelay
	},
	retryCondition: async (error: AxiosError<any>) => {
		if (error.response?.status === 401) {
			return false;
		}
		
		if (configJson.retryOptions.retryErrorCode === 200) {
			return error.response?.status !== configJson.retryOptions.retryErrorCode
		}
		else {
			return error.response?.status === configJson.retryOptions.retryErrorCode;
		}
	}
});

export class RestClient {
	private getHeaders(authToken: string) {
		var headers = { authorization: `Bearer ${authToken}` };
		return { headers: headers };
	}

	private getApiResponse<T>(statusCode: number, content: T): ApiResponse<T> {
		return { statusCode: statusCode, content: content, errorMessage: "", errorContent: {}, isSuccessStatusCode: statusCode === 200 }
	}

	private getApiResponseError<T>(error: AxiosError): Error {
		if (error.response?.status === 401) {
			return new UnauthorizedError<T>(error.message, error.response);
		}

		return new ConnectionError<T>(error.message, error.response);
	}

	public login = async (authToken: string, outlookToken: string): Promise<ApiResponse<LoginModel>> => {
		logger.debug("POST /api/login/");
		var headers = { authorization: `Bearer ${authToken}`, outlookToken: outlookToken };
		try {
			let response = await axios.post<LoginModel>("/api/login/", {}, { headers: headers });
			return this.getApiResponse<LoginModel>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<LoginModel>(error as AxiosError);
		}
	}

	public getPlatformInfo = async (platformType: Office.PlatformType, language: string): Promise<ApiResponse<PlatformConfig>> => {
		logger.debug(`GET api/settings/platform/${platformType}`);
		try {
			let response = await axios.get<PlatformConfig>(`api/settings/platform/${platformType}`, {params: {language: language}});
			return this.getApiResponse<PlatformConfig>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<PlatformConfig>(error as AxiosError);
		}
	}

	public processMail = async (authToken: string, emailInfo: EmailInfo): Promise<ApiResponse<ApiResponseView>> => {
		logger.debug(`POST /api/mail`);
		try {
			let response = await axios.post<ApiResponseView>("/api/mail", emailInfo, this.getHeaders(authToken));
			return this.getApiResponse<ApiResponseView>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<ApiResponseView>(error as AxiosError);
		}
	}

	public cancelDlp = async (authToken: string): Promise<ApiResponse<boolean>> => {
		logger.debug(`DELETE /api/mail/dlp`);
		try {
			let response = await axios.delete("/api/mail/dlp", this.getHeaders(authToken));
			return this.getApiResponse<boolean>(response.status, true);
		} catch (error) {
			throw this.getApiResponseError<boolean>(error as AxiosError);
		}
	}

	public postProcess = async (authToken: string, SendConfirmed: ConfirmInfo): Promise<ApiResponse<OnSendPostProcessContextView>> => {
		logger.debug(`PATCH /api/mail`);
		try {
			let response = await axios.patch<OnSendPostProcessContextView>("/api/mail", SendConfirmed, this.getHeaders(authToken));
			return this.getApiResponse<OnSendPostProcessContextView>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<OnSendPostProcessContextView>(error as AxiosError);
		}
	}

	public sendAttachmentPassword = async (authToken: string, password: string): Promise<ApiResponse<boolean>> => {
		logger.debug(`PATCH /api/mail/dlp/attachments`);
		try {
			let response = await axios.patch<boolean>("/api/mail/dlp/attachments", { password: password }, this.getHeaders(authToken));
			return this.getApiResponse<boolean>(response.status, true);
		} catch (error) {
			throw this.getApiResponseError<boolean>(error as AxiosError);
		}
	}

	public sendAttachmentContent = async (authToken: string, outlookAttachment: OutlookAttachment): Promise<ApiResponse<boolean>> => {
		logger.debug(`PATCH /api/mail/dlp/attachment`);
		try {
			let response = await axios.patch<boolean>("/api/mail/dlp/attachment", outlookAttachment, this.getHeaders(authToken));
			return this.getApiResponse<boolean>(response.status, true);
		} catch (error) {
			throw this.getApiResponseError<boolean>(error as AxiosError);
		}
	}

	public getAppInfo = async (): Promise<ApiResponse<AppInfo>> => {
		logger.debug(`GET /api/settings/version`);
		try {
			let response = await axios.get<AppInfo>("/api/settings/version");
			return this.getApiResponse<AppInfo>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<AppInfo>(error as AxiosError);
		}
	};

	public getARRAffinityActive = async (): Promise<ApiResponse<boolean>> => {
		logger.debug(`GET /api/settings/arraffinity/active`);
		try {
			let response = await axios.get<boolean>("/api/settings/arraffinity/active");
			return this.getApiResponse<boolean>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<boolean>(error as AxiosError);
		}
	};

	public getLogo = async (): Promise<ApiResponse<string>> => {
		logger.debug(`GET /api/settings/logo`);
		try {
			let response = await axios.get<string>("/api/settings/logo");
			return this.getApiResponse<string>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<string>(error as AxiosError);
		}
	};

	public getAppSettings = async (authToken: string): Promise<ApiResponse<string>> => {
		logger.debug(`GET /api/settings`);
		try {
			let response = await axios.get<string>("/api/settings", this.getHeaders(authToken));
			return this.getApiResponse<string>(response.status, response.data);
		} catch (error) {
			throw this.getApiResponseError<string>(error as AxiosError);
		}
	};
}

export const restClient = new RestClient();

export class ConnectionError<T> extends Error {
	public response: AxiosResponse<T> | null;
	constructor(message: string, response: AxiosResponse<T> | null = null) {
		super(message);
		this.message = message;
		this.name = "ConnectionError";
		this.response = response;
	}
}

export class UnauthorizedError<T> extends Error {
	public response: AxiosResponse<T> | null;
	constructor(message: string, response: AxiosResponse<T> | null = null) {
		super(message);
		this.message = message;
		this.name = "UnauthorizedError";
		this.response = response;
	}
}