import * as React from 'react';
import * as Msal from "msal";
import OutlookSpinner from '../OutlookSpinner';
import { LoginResult, LoginResultStatus } from './LoginTypes';
import { StorageDatatype, storageUtils } from '../../lib/Utils';
import { restClient } from '../../RestClient';

export default class LoginPage extends React.Component {
	authCallback(error, response) {
		let loginresult: LoginResult = {
			status: LoginResultStatus.Failed,
			accessToken: "",
			error: null
		}

		if (error) {
			loginresult.error = error;
			Office.context.ui.messageParent(JSON.stringify(loginresult));
        } else {
			if (response.tokenType === "id_token") {
				storageUtils.localSet(StorageDatatype.LoggedIn, "yes");
            } else {
				loginresult.status = LoginResultStatus.Succeeded;
				loginresult.accessToken = response.accessToken;
				Office.context.ui.messageParent(JSON.stringify(loginresult));
            }
        }
    }

	componentDidMount() {
		Office.onReady(async (info) => {
			if (info.host !== null) {
				let safeSendToken = storageUtils.localGet(StorageDatatype.SafeSendToken);
				let appSettings = await restClient.getAppSettings(safeSendToken);

				let msalConfig: Msal.Configuration = {
					auth: {
						clientId: appSettings.content != null ? appSettings.content : "",
						authority: "https://login.microsoftonline.com/common",
						redirectUri: `${window.location.origin}/login`,
						navigateToLoginRequestUrl: false
					},
					cache: {
						cacheLocation: "localStorage", // Needed to avoid "User login is required" error.
						storeAuthStateInCookie: true  // Recommended to avoid certain IE/Edge issues.
					}
				};

				let userAgentApp = new Msal.UserAgentApplication(msalConfig);
				let requestObj = {
					scopes: ["https://graph.microsoft.com/Files.ReadWrite", "https://graph.microsoft.com/Mail.ReadWrite.Shared", "https://graph.microsoft.com/Calendars.ReadWrite.Shared"]
				};
				userAgentApp.handleRedirectCallback(this.authCallback);

				// The very first time the add-in runs on a developer's computer, msal.js hasn't yet
				// stored login data in localStorage. So a direct call of acquireTokenRedirect 
				// causes the error "User login is required". Once the user is logged in successfully
				// the first time, msal data in localStorage will prevent this error from ever hap-
				// pening again; but the error must be blocked here, so that the user can login 
				// successfully the first time. To do that, call loginRedirect first instead of 
				// acquireTokenRedirect.
				if (storageUtils.localGet(StorageDatatype.LoggedIn) === "yes") {
					userAgentApp.acquireTokenRedirect(requestObj);
				}
				else {
					// This will login the user and then the (response.tokenType === "id_token")
					// path in authCallback below will run, which sets localStorage.loggedIn to "yes"
					// and then the dialog is redirected back to this script, so the 
					// acquireTokenRedirect above runs.
					userAgentApp.loginRedirect(requestObj);
				}
			}
		});
	}

    render() {
		return (
			<OutlookSpinner message="Authenticating..." />
        );
    }
}