/*global Office */  //Required for this to be found.  see: https://github.com/OfficeDev/office-js-docs-pr/issues/691

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fluentui/react/dist/css/fabric.min.css';
import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ApiResponseView } from './Backend/BackendTypes'
import { Modules } from '../LogConfiguration';
import { safeSendLogging } from '../lib/Logger';
import { IStackTokens, Panel, PanelType, Separator, Stack } from '@fluentui/react';
import { StorageDatatype, storageUtils } from '../lib/Utils';
import LostConnectionPage from './LostConnectionPage';

const logger = safeSendLogging.getLogger(Modules.SafeSend_OfficeDialog);

const stackToken: IStackTokens = {
    padding: "0px 0px 0px 50px",
};

export interface ForbiddenRecipientDialogProps {
    dialogId: string;
    apiResponseView: ApiResponseView;
}

export interface ForbiddenRecipientDialogState {
    isConnectionLost: boolean;
}

export default class ForbiddenRecipientDialog extends React.Component<ForbiddenRecipientDialogProps, ForbiddenRecipientDialogState>{
    sendEvent: any;
    constructor(props, context) {
        super(props, context);
        this.state = {
            isConnectionLost: false
        }
        this.closeDialog = this.closeDialog.bind(this);
    }

    componentDidMount() {
        this.setDialogTimeout(240);

        window.addEventListener("storage", () => {
            let dialogId = storageUtils.localGet(StorageDatatype.DialogId) as string;
            if (this.props.dialogId !== dialogId) {
                this.setState({
                    isConnectionLost: true
                });
            }
        });
    }

    closeDialog() {
        let dialogValues = JSON.stringify({ send: false, encrypt: false });
        Office.context.ui.messageParent(dialogValues);
    }

    setDialogTimeout(timeoutSeconds: number) {
        var timeoutMs = timeoutSeconds * 1000;
        setTimeout(dialogTimeout, timeoutMs);
        function dialogTimeout() {
            logger.info("Dialog timeout reached, closing dialog.");
            Office.context.ui.messageParent(JSON.stringify({ send: false, encrypt: false }));
        }
    }

    render() {
        let recipients: string[] = [];
        let i = 0;
        for (; i++; i < this.props.apiResponseView.onSendProcessContex.forbiddenRecipients.length && i < 10) {
            let x = this.props.apiResponseView.onSendProcessContex.forbiddenRecipients[i];
            let name = x.name + (x.isList ? " (" + this.props.apiResponseView.onSendProcessContex.settings._stringMainListIdentifier + ")" : "");
            recipients.push(name);
        }

        const onRenderFooterContent = () => (
            <Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft} id="child" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Stack>
                </Stack>
                <Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft} id="child" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Separator vertical={true} />
                    <DefaultButton onClick={() => this.closeDialog()}
                        text={this.props.apiResponseView.onSendProcessContex?.settings._stringMainCancelButton} />
                </Stack>
            </Stack>
        )

        return (
            <>
                {
					this.state.isConnectionLost &&
					<LostConnectionPage />
                }
                {
                    !this.state.isConnectionLost &&
                    <Panel
                        isOpen={true}
                        type={PanelType.smallFluid}
                        onRenderFooterContent={onRenderFooterContent}
                        hasCloseButton={false}
                        headerText={this.props.apiResponseView.onSendProcessContex.confirmationFlags?.mainTitle}
                        isFooterAtBottom={true}
                    >
                        <Label>{this.props.apiResponseView.onSendProcessContex.settings._stringForbiddenRecipientTitle}</Label>
                        {
                            this.props.apiResponseView.onSendProcessContex.forbiddenRecipients.length === 1 && 
                            <Label>{this.props.apiResponseView.onSendProcessContex.settings._stringForbiddenRecipient}</Label>
                        }
                        {
                            this.props.apiResponseView.onSendProcessContex.forbiddenRecipients.length !== 1 &&
                            <Label>{this.props.apiResponseView.onSendProcessContex.settings._stringForbiddenRecipients}</Label>
                        }
                        {
                            <Stack tokens={stackToken}>
                                {
                                    this.props.apiResponseView.onSendProcessContex.forbiddenRecipients.slice(0, 10).map((fr, i) => {
                                        return (<Label key={i}>{fr.name + (fr.isList ? ` (${this.props.apiResponseView.onSendProcessContex.settings._stringMainListIdentifier})` : "")}</Label>)
                                    })
                                }
                                {
                                    this.props.apiResponseView.onSendProcessContex.forbiddenRecipients.length > 10 &&
                                    <Label>...</Label>
                                }
                            </Stack>
                        }
                    </Panel>
                }
            </>
        );
    }
}
