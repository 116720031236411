import * as React from 'react';
import { FontIcon, Label, mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import { browserName, engineName, engineVersion, fullBrowserVersion, osName, osVersion } from 'react-device-detect';
import { Text } from '@fluentui/react/lib/Text';

const iconClass = mergeStyles({
    padding: 0,
    fontSize: '48px',
    verticalAlign: 'middle',
    maxHeight: '48px',
    maxWidth: '48px',
});

const classNames = mergeStyleSets({
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    info: [{ color: '#6c757d', cursor: 'default' }, iconClass],
});

export default class LostConnectionPage extends React.Component {
    render() {
        return (
            <Stack className={classNames.box}>
                <FontIcon iconName="PlugDisconnected" className={classNames.info} />
                <Label>
                    Lost connection: To continue, close this window and refresh the browser (F5).
                </Label>
				<Text variant={'small'} block>{osName} {osVersion} - {browserName} {fullBrowserVersion} - {engineName} {engineVersion}</Text>
            </Stack>
        );
    }
}