import { browserName, engineName, engineVersion, fullBrowserVersion, osName, osVersion } from "react-device-detect";
import { GetMajorPlatformVersion } from "./UtilsJs";

export const systemInfo = async (): Promise<string> => {
	let detectedOSName = osName;
	let detectedOSVersion = osVersion;


	if (detectedOSName.toLowerCase() === "windows" && detectedOSVersion === "10") {
        if (browserName.toLowerCase() === "firefox"){
            return `${detectedOSName} - ${browserName} ${fullBrowserVersion} - ${engineName} ${engineVersion}`;
        }
        const majorVersion = await GetMajorPlatformVersion();
        if (majorVersion >= 13) {
            detectedOSVersion = "11";
        }
	}
	return `${detectedOSName} ${detectedOSVersion} - ${browserName} ${fullBrowserVersion} - ${engineName} ${engineVersion}`;
};

function cleanseAssertionOperators(parsedName: string): string {
    return parsedName.replace(/[?!]/g, "");
}

export interface NameofOptions {
    /**
     * Take only the last property of nested properties.
    */
    lastProp?: boolean;
}

export function nameof<T extends Object>(nameFunction: ((obj: T) => any) | { new(...params: any[]): T }, options?: NameofOptions): string {
    const fnStr = nameFunction.toString();

    // ES6 class name:
    // "class ClassName { ..."
    if (
        fnStr.startsWith("class ")
        // Theoretically could, for some ill-advised reason, be "class => class.prop".
        && !fnStr.startsWith("class =>")
    ) {
        return cleanseAssertionOperators(
            fnStr.substring(
                "class ".length,
                fnStr.indexOf(" {")
            )
        );
    }

    // ES6 prop selector:
    // "x => x.prop"
    if (fnStr.includes("=>")) {
        return cleanseAssertionOperators(
            fnStr.substring(
                fnStr.indexOf(".") + 1
            )
        );
    }

    // ES5 prop selector:
    // "function (x) { return x.prop; }"
    // webpack production build excludes the spaces and optional trailing semicolon:
    //   "function(x){return x.prop}"
    // FYI - during local dev testing i observed carriage returns after the curly brackets as well
    // Note by maintainer: See https://github.com/IRCraziestTaxi/ts-simple-nameof/pull/13#issuecomment-567171802 for explanation of this regex.
    const matchRegex = /function\s*\(\w+\)\s*\{[\r\n\s]*return\s+\w+\.((\w+\.)*(\w+))/i;

    const es5Match = fnStr.match(matchRegex);

    if (es5Match) {
        return (options && options.lastProp)
            ? es5Match[3]
            : es5Match[1];
    }

    // ES5 class name:
    // "function ClassName() { ..."
    if (fnStr.startsWith("function ")) {
        return cleanseAssertionOperators(
            fnStr.substring(
                "function ".length,
                fnStr.indexOf("(")
            )
        );
    }

    // Invalid function.
    throw new Error("ts-simple-nameof: Invalid function.");
}

export const stringFormat = (str: string, ...args: string[]) => str.replace(/{(\d+)}/g, (_match, index) => args[index] || '')

export enum WebBrowser {
    Safari = "Safari",
    WebKit = "WebKit"
}

export enum StorageDatatype {
    DialogId,
    ApiResponse,
	SafeSendToken,
	LoggedIn,
	OutlookAccessToken,
	Log,
	AuditStringDlpResults
}

export enum SignalRHubType {
	AddinHub = "/addinhub",
	UIHub = "/uihub"
}

export class StorageUtils {
    public sessionExists(sesssionStorageDatatype: StorageDatatype): boolean {
        let sessionStorageData = window.sessionStorage.getItem(StorageDatatype[sesssionStorageDatatype]);

        return sessionStorageData !== null;
    }

    public sessionGet(sessionStorageDatatype: StorageDatatype): any {
        return window.sessionStorage.getItem(StorageDatatype[sessionStorageDatatype]);
    }

    public sessionSet(sessionStorageDatatype: StorageDatatype, value: string): void {
        window.sessionStorage.setItem(StorageDatatype[sessionStorageDatatype], value);
    }

    public sessionRemove(sessionStorageDatatype: StorageDatatype): void {
        window.sessionStorage.removeItem(StorageDatatype[sessionStorageDatatype]);
    }

    public sessionClear(): void {
        window.sessionStorage.clear();
    }

    public localExists(storageDatatype: StorageDatatype): boolean {
        let storageData = window.localStorage.getItem(StorageDatatype[storageDatatype]);
        return storageData !== null;
    }

    public localGet(storageDatatype: StorageDatatype): any {
        return window.localStorage.getItem(StorageDatatype[storageDatatype]);
    }

    public localSet(storageDatatype: StorageDatatype, value: string): void {
        window.localStorage.setItem(StorageDatatype[storageDatatype], value);
    }

    public localRemove(storageDatatype: StorageDatatype): void {
        window.localStorage.removeItem(StorageDatatype[storageDatatype]);
    }

    public localClear(): void {
        window.localStorage.clear();
    }
}

export function InjectLinkToString(separator: string, source: string, link: string): string {
    let parts = source.split(separator);
    let startLink = `<a target="_blank" href="${link}">`;
    let endLink = '</a>';

	return fixupHtml(parts[0].concat(startLink).concat(parts[1]).concat(endLink).concat(parts[2]));
}

export function fixupHtml(html: string): string {
	return linkToNewWindow(replaceColorTagToStyle(html));
}

function linkToNewWindow(html: string): string {
	return html.replace(/<a\s+href=/gi, "<a target=\"_blank\" href=");
}

function replaceColorTagToStyle(source: string): string {
    // search for color tags
    const regexp = new RegExp("color=(.+?)(?=>)", "g");
    let colors: string[] = [];
    let match;
    while ((match = regexp.exec(source)) !== null) {
        colors.push(match[0].replace("color=", ""));
    }

    // Replace style instead of tag
    colors.forEach((color) => {
        if (color.includes(',')) {
            source = source.replace("color=" + color, 'span style="color:rgb(' + color + ')"');
        } else {
            source = source.replace("color=" + color, 'span style="color:' + color + '"');
        }
        source = source.replace("/color", "/span");
    });

    return source;
}

export const storageUtils = new StorageUtils();