import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fluentui/react/dist/css/fabric.min.css';
import App from './components/App/App';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import LandingPage from './components/LandingPage';
import { processOnSendEvent, processOnSendEventLog } from './lib/AddIn';
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import LoginPage from './components/Login/LoginPage';
import LandingPageDesktop from './components/LandingPageDesktop';

initializeIcons();
initializeFileTypeIcons();

declare global {
	interface Window { onSendEvent: any, onSendEventLog: any }
}

window.onSendEvent = function onSendEvent(sendEvent) {
	processOnSendEvent(sendEvent);
}

window.onSendEventLog = function onSendEventLog(sendEvent) {
	processOnSendEventLog(sendEvent);
}

const routing = (
	<Router>
		<Switch>
			<Route exact path="/status" component={LandingPage} />
			<Route exact path="/dialog" component={App} />
			<Route exact path="/login" component={LoginPage} />
			<Route exact path="/desktop" component={LandingPageDesktop} />
			{/*<Route exact path="/lost" component={LostConnectionPage} />*/}
		</Switch>
	</Router>
)

ReactDOM.render(routing, document.getElementById('container'));