import { EventEmitter } from 'events';
import { Modules } from '../LogConfiguration';
import { StorageDatatype, storageUtils } from './Utils';

//based on https://adrianhall.github.io/cloud/2019/06/30/building-an-efficient-logger-in-typescript/
export enum LogLevel {
	TRACE = "TRACE",
	DEBUG = "DEBUG",
	INFO = "INFO",
	WARN = "WARN",
	ERROR = "ERROR"
}

class LogManager extends EventEmitter {
	private minLevel: LogLevel = LogLevel.INFO;

    // Prevent the console logger from being added twice
	private consoleLoggerRegistered: boolean = false;
	private logAppenders: LogAppender[] = [new ConsoleAppender()];

	public setAppender(logAppenders: LogAppender[]) {
		this.logAppenders = logAppenders;
	}

	public setMinLevel(minLevel: LogLevel): LogManager {
		this.minLevel = minLevel;
        return this;
    }

	public getLogger(module: Modules): Logger {
        return new Logger(this, module, this.minLevel);
    }

    public onLogEntry(listener: (logEntry: LogEntry) => void): LogManager {
        this.on("log", listener);
        return this;
    }

	public registerConsoleLogger(): LogManager {
        if (this.consoleLoggerRegistered) return this;

		this.onLogEntry((logEntry) => {
			const msg = `[${new Date().toLocaleString()}] [${logEntry.level}] [${Modules[logEntry.module]}]`;
			let params = logEntry.message.concat(...logEntry.optionalParams, `[${logEntry.location}]`);
			this.logAppenders.forEach((logAppender) => {
				switch (logEntry.level) {
					case LogLevel.TRACE:
						logAppender.trace(msg, ...params);
						break;
					case LogLevel.DEBUG:
						logAppender.debug(msg, ...params);
						break;
					case LogLevel.INFO:
						logAppender.info(msg, ...params);
						break;
					case LogLevel.WARN:
						logAppender.warn(msg, ...params);
						break;
					case LogLevel.ERROR:
						logAppender.error(msg, ...params);
						break;
					default:
						logAppender.log(msg, ...params);
				}				
			})
        });

        this.consoleLoggerRegistered = true;
        return this;
    }
}

class Logger {
    private logManager: EventEmitter;
    private minLevel: number;
	private module: Modules;
    private readonly levels: { [key: string]: number } = {
        "TRACE": 1,
        "DEBUG": 2,
        "INFO": 3,
        "WARN": 4,
        "ERROR": 5
    };

	constructor(logManager: EventEmitter, module: Modules, minLevel: LogLevel) {
        this.logManager = logManager;
        this.module = module;
        this.minLevel = this.levelToInt(minLevel);
    }

    /**
     * Converts a string level (trace/debug/info/warn/error) into a number 
     * 
     * @param minLevel 
     */
	private levelToInt(minLevel: LogLevel): number {
        if (minLevel in this.levels)
            return this.levels[minLevel];
        else
            return 99;
	}

	private getLocation(): string {
		// Obtain the line/file through a thoroughly hacky method
		// This creates a new stack trace and pulls the caller from it.  If the caller
		// if .trace()
		const error = new Error("");
		let location = ""
		if (error.stack) {
			const cla = error.stack.split("\n");
			var idx = 1;
			while (idx < cla.length && cla[idx].includes("at Logger.")) idx++;
			if (idx < cla.length) {
				location = cla[idx].slice(cla[idx].indexOf("at ") + 3, cla[idx].length);
			}
		}

		location = location?.replace(/_callee\d+\$|e.value/, "");

		return location;
	}

	public setMinLevel(minLevel: LogLevel) {
		this.minLevel = this.levelToInt(minLevel);
	}

    /**
     * Central logging method.
     * @param logLevel 
     * @param message 
     */
	public log(logLevel: LogLevel, message: any, ...optionalParams: any[]): void {
        const level = this.levelToInt(logLevel);
		if (level < this.minLevel) return;
		const logEntry: LogEntry = {
			level: logLevel,
			module: this.module,
			message: [message],
			optionalParams: optionalParams,
			location: this.getLocation()
		};

        this.logManager.emit("log", logEntry);
    }

	public trace(message?: any, ...optionalParams: any[]): void { this.log(LogLevel.TRACE, message, optionalParams); }
	public debug(message?: any, ...optionalParams: any[]): void { this.log(LogLevel.DEBUG, message, optionalParams); }
	public info(message?: any, ...optionalParams: any[]): void { this.log(LogLevel.INFO, message, optionalParams); }
	public warn(message?: any, ...optionalParams: any[]): void { this.log(LogLevel.WARN, message, optionalParams); }
	public error(message?: any, ...optionalParams: any[]): void { this.log(LogLevel.ERROR, message, optionalParams); }
}

interface LogEntry {
	level: LogLevel;
	module: Modules;
	location?: string;
	message: any[];
	optionalParams: any[]
}

interface LogAppender {
	trace(message?: any, ...optionalParams: any[]): void;
	debug(message?: any, ...optionalParams: any[]): void;
	info(message?: any, ...optionalParams: any[]): void;
	warn(message?: any, ...optionalParams: any[]): void;
	error(message?: any, ...optionalParams: any[]): void;
	log(message?: any, ...optionalParams: any[]): void;
}

class ConsoleAppender implements LogAppender {
    trace(message?: any, ...optionalParams: any[]): void {
		console.trace(message, ...optionalParams);
	}

    debug(message?: any, ...optionalParams: any[]): void {
		console.info(message, ...optionalParams);
	}

    info(message?: any, ...optionalParams: any[]): void {
		console.info(message, ...optionalParams);	
	}

    warn(message?: any, ...optionalParams: any[]): void {
		console.warn(message, ...optionalParams);	
	}

    error(message?: any, ...optionalParams: any[]): void {
		console.error(message, ...optionalParams);	
	}

	log(message?: any, ...optionalParams: any[]): void {
		console.log(message, ...optionalParams);
	}
}

class FileAppender implements LogAppender {
	private getBytesSizeLogRemainingSpace = (): number => {
		let log: string = storageUtils.localGet(StorageDatatype.Log);

		return 512 * 1024 - log.length;
	}

	private prepareLocalStorage = (newEntry: string): boolean => {
		const newEntryBytesSize = newEntry.length;
		let success = true;
		while (success && newEntryBytesSize > this.getBytesSizeLogRemainingSpace()) {
			try {
				let log: string = storageUtils.localGet(StorageDatatype.Log);
				let initSize = log.length;
				let index = log.indexOf("\n");
				if (index !== -1) {
					const nextValidPosition = index + 1;
					log = log.substring(nextValidPosition, log.length);
					storageUtils.localSet(StorageDatatype.Log, log);
				}

				success = log.length < initSize;
			} catch (e) {
				success = false;
			}
		}

		return success;
	}

	private saveLog(message?: any, ...optionalParams: any[]) {
		let newEntry = `${message} ${JSON.stringify(optionalParams)}`;
		if (this.prepareLocalStorage(newEntry)) {
			let log = storageUtils.localGet(StorageDatatype.Log);
			log = `${log} ${newEntry}\n`;
			storageUtils.localSet(StorageDatatype.Log, log);
		}
	}

	trace(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}

	debug(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}

	info(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}

	warn(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}

	error(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}

	log(message?: any, ...optionalParams: any[]): void {
		this.saveLog(message, ...optionalParams);
	}
}

class SafeSendLogging {
	private consoleAppender = new ConsoleAppender();
	private fileAppender = new FileAppender();
	private logManager = new LogManager();

	public getLogger(module: Modules): Logger {
		let loggers = [this.consoleAppender]
		if (storageUtils.localExists(StorageDatatype.Log)) {
			this.logManager.setMinLevel(LogLevel.TRACE);
			loggers = [this.consoleAppender, this.fileAppender];
		}

		this.logManager.registerConsoleLogger();
		this.logManager.setAppender(loggers);
		return this.logManager.getLogger(module);
	}

	public setMinLevel(level: LogLevel) {
		this.logManager.setMinLevel(level);
	}
}

export const safeSendLogging = new SafeSendLogging();