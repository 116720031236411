import * as React from 'react';
import { Checkbox, IStackTokens, IconButton, IIconProps, Stack, SharedColors } from '@fluentui/react';
import { IPersonaConfirmableProps, IPersonaConfirmableStates } from './PersonaConfirmableTypes';
import ConfirmableLabel from '../Shared/ConfirmableLabel';

const stackTokens: IStackTokens = {
    padding: `${0}px ${0}px ${5}px ${0}px`,
};

export default class PersonaConfirmable extends React.Component<IPersonaConfirmableProps, IPersonaConfirmableStates> {
    constructor(props) {
        super(props);
        this.state = {
            stateUpdate: false,
            checked: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps: IPersonaConfirmableProps, prevState: IPersonaConfirmableStates) {
        if (prevState.stateUpdate) {
            return ({ stateUpdate: false })
        }

        if (nextProps.isRemoved) {
            return ({checked: false, stateUpdate: true})
        }

        if (nextProps.checked !== prevState.checked) {
            return ({ checked: nextProps.checked});
        }

        return null
    }

    handleChange(e) {
        let checked = (e?.target as HTMLInputElement).checked;
        this.setState({
            checked: checked,
            stateUpdate: true
        });
        this.props.onChange(this.props.id, checked);
    }

    handleRemove = () => {
        this.props.handleRemoved(this.props.id, this.props.recipientType)
    }

    shouldRenderRemovableIcons(){
        if (!this.props.isRecipientAttachmentRemovalEnabled){
            return false;
        }

        return true;
    }

    removeIcon: IIconProps = { iconName: "Cancel", styles:{root: {color: SharedColors.red20}}};
    AddIcon: IIconProps = { iconName: "Add", styles: {root: {color: SharedColors.blue10}}};

    render() {
        return (
            <Stack reversed={this.props.alignRightToLeft} horizontal tokens={stackTokens} verticalAlign={'center'}>
                { 
                    this.props.showCheckBox &&
                    <Checkbox disabled={this.props.disabled || this.props.isRemoved} onChange={this.handleChange} checked={this.state.checked} />
                }
                {
                    <ConfirmableLabel 
                        isRemoved={this.props.isRemoved}
                        colorDomains={this.props.colorDomains}
                        color={this.props.color}
                        text={this.props.text}
                    />
                }
                {
                    this.shouldRenderRemovableIcons() 
                    ? (
                        this.props.isRemoved
                        ? <IconButton disabled={this.props.isRecipientRemovalDisabled} iconProps={this.AddIcon} title='Add' ariaLabel='Add' onClick={this.handleRemove}></IconButton>
                        : <IconButton disabled={this.props.isRecipientRemovalDisabled} iconProps={this.removeIcon} title='Remove' ariaLabel='Remove' onClick={this.handleRemove}></IconButton>
                    ) 
                    : null
                }
            </Stack>
        );
    }
}