import { Label, ILabelStyles, SharedColors } from '@fluentui/react';
import React from "react";

interface ILabelConfirmableProps{
   text: string;
   isRemoved?: boolean,
   colorDomains?: boolean,
   color?: string;
}

export default class ConfirmableLabel extends React.Component<ILabelConfirmableProps> {
   removedLabelStyles: ILabelStyles = {root:{ color: SharedColors.red20, textDecoration: "line-through"}};
   colorDomainStyles: ILabelStyles = { root: { color : this.props.color } };

   render(){ 
      if (this.props.isRemoved)
      {
         return <Label styles={this.removedLabelStyles}> {this.props.text} </Label>;
      }

      if (this.props.colorDomains)
      {
         return <Label styles={this.colorDomainStyles}> {this.props.text} </Label>;
      }

      return <Label> {this.props.text} </Label>;
   }
}