import { ActionOnMatch, ClientKeywordDomainResultView, ContentScanningResultView, DlpItemResultView, DlpResultSourceTypeEnum, DlpResultView, SettingsView, UnprotectedAttachmentsMode } from "../Backend/BackendTypes";
import { IDlp } from "../Confirmable/ConfirmableTypes";
import { AppStateUI, GroupByAttachments, IProcessedContentScanningResults } from "./AppTypes";
import { stringFormat, nameof } from "../../lib/Utils";

export class AppUtils {
    private getGroupAttachment<T extends DlpResultView>(results: T[], isContentScanning: boolean): T[] {
        let copyResults: T[] = [];
        let groupBy = {};    
        results.forEach((item) => {
            if (item.sourceType === DlpResultSourceTypeEnum.Attachment) {
                let key: string = isContentScanning ? item.firstMatch : item.clientKeyword;
                let group = groupBy[key] as T;
                if (group) {
                    group.matchCount += item.matchCount;
                    group.files.push(item.attachmentPath)
                } else {
                    item.files.push(item.attachmentPath);
                    item.groupBy = true;
                    groupBy[key] = item;
                }
            } else {
                copyResults.push(item);
            }
        });

        for (let key in groupBy) {
            var item = groupBy[key] as T;
            var filterFiles = item.files.filter((elem, index, self) => {
                return index === self.indexOf(elem);
            })

            item.files = filterFiles;
            let length = item.files.join().length;
            item.tooLong = false;
            if (length > 60) {
                if (length <= 5000) {
                    item.useTooltip = true;
                } else {
                    item.tooLong = true;
                    item.files = [];
                }
            } else {
                item.useTooltip = false;
            }

            item.files = item.files.sort(function (a, b) {
                return a > b ? 1 : -1;
            })

            copyResults.push(item);
        }

        return copyResults;
    }

    private getContentScanningAction(currentAction: ActionOnMatch, newAction: ActionOnMatch): ActionOnMatch {
        switch (currentAction) {
            case ActionOnMatch.Confirm:
                if (newAction === ActionOnMatch.Inform) {
                    return currentAction;
                }
                break;
            case ActionOnMatch.ConfirmText:
                if (newAction === ActionOnMatch.Inform || newAction === ActionOnMatch.Confirm) {
                    return currentAction;
                }
                break;
            case ActionOnMatch.Deny:
                return currentAction;
        }

        return newAction;
    }

    public getStateForUpdateUI(
        settings: SettingsView,
        clientKeywordDomainResults: ClientKeywordDomainResultView[],
        contentScanningResults: ContentScanningResultView[],
        currentContentScanningAction: ActionOnMatch,
        groupClientKeywordAttachments: boolean,
        groupContentScanningAttachments: boolean,
        dlpItemResults: DlpItemResultView[],
        isDlpWaiting: boolean,
        scanTimedOut: boolean,
        countUnprotectedAttachments: number
    ): AppStateUI {
        let dlps: IDlp[] = [];
        dlpItemResults.forEach((dlpItemResult) => {
            let processedContentScanningResults = this.getProcessedContentScanningResults(settings, currentContentScanningAction, dlpItemResult.contentScanResults);
            currentContentScanningAction = processedContentScanningResults.actionOnMatch;
            contentScanningResults = contentScanningResults.concat(processedContentScanningResults.contentScanningResultView);

            let processedClientKeywordDomainResults = this.getProcessedClientKeywordDomainResults(settings, dlpItemResult.clientKeywordDomainResults);
            clientKeywordDomainResults = clientKeywordDomainResults.concat(processedClientKeywordDomainResults);

            if (dlpItemResult.isAttachment) {
               dlps.push({ id: dlpItemResult.id, status: dlpItemResult.status, statusString: dlpItemResult.statusString });
               if (dlpItemResult.containsUnprotectedAttachment) {
                  countUnprotectedAttachments++;
               }
            }
        });

       let unprotectedAttachmentMessage = "";
       if (countUnprotectedAttachments === 1) {
          switch (settings._unprotectedAttachmentsMode) {
             case UnprotectedAttachmentsMode.Confirm:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentConfirm;
                break;
             case UnprotectedAttachmentsMode.Deny:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentDeny;
                break;
             default:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentNotify;
                break;
          }
       }
       else {
          switch (settings._unprotectedAttachmentsMode) {
             case UnprotectedAttachmentsMode.Confirm:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentsConfirm;
                break;
             case UnprotectedAttachmentsMode.Deny:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentsDeny;
                break;
             default:
                unprotectedAttachmentMessage = settings._stringNotEncryptedAttachmentsNotify;
                break;
          }
       }


        if (groupClientKeywordAttachments) {
            clientKeywordDomainResults = this.getGroupAttachment(clientKeywordDomainResults, false);
        }

        if (groupContentScanningAttachments)
        {
            contentScanningResults = this.getGroupAttachment(contentScanningResults, true);
        }

        clientKeywordDomainResults = this.copyAndSort(clientKeywordDomainResults, `${nameof<ClientKeywordDomainResultView>(c => c.attachmentPath)}`, false);

        contentScanningResults = this.copyAndSort(contentScanningResults, `${nameof<ClientKeywordDomainResultView>(c => c.attachmentPath)}`, false);

        return {
            isDlpWaiting: isDlpWaiting,
            scanTimedOut: scanTimedOut,
            clientKeywordDomainResults: clientKeywordDomainResults,
            contentScanningResults: contentScanningResults,
            contentScanningAction: currentContentScanningAction,
            dlps: dlps,
            countUnprotectedAttachments: countUnprotectedAttachments,
            unprotectedAttachmentMessage: unprotectedAttachmentMessage
        };
    }

    public copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    public getProcessedClientKeywordDomainResults(settings: SettingsView, clientKeywordDomainResults: ClientKeywordDomainResultView[]): ClientKeywordDomainResultView[] {
        clientKeywordDomainResults.forEach((clientKeywordDomainResult) => {
            switch (clientKeywordDomainResult.sourceType) {
                case DlpResultSourceTypeEnum.Attachment:
                    clientKeywordDomainResult.sourceTypeString = settings._stringContentScanningEmailAttachment;
                    break;
                case DlpResultSourceTypeEnum.Body:
                    clientKeywordDomainResult.sourceTypeString = settings._stringContentScanningEmailContent;
                    break;
                case DlpResultSourceTypeEnum.Filename:
                    clientKeywordDomainResult.sourceTypeString = settings._stringContentScanningEmailFileName;
                    break;
                case DlpResultSourceTypeEnum.Subject:
                    clientKeywordDomainResult.sourceTypeString = settings._stringContentScanningEmailSubject;
                    break;
            }
        });

        return clientKeywordDomainResults;
    }

    public getProcessedContentScanningResults(settings: SettingsView, currentAction: ActionOnMatch, contentScanResults: ContentScanningResultView[]): IProcessedContentScanningResults {
        contentScanResults.forEach((contentScanningResult) => {
            currentAction = this.getContentScanningAction(currentAction, contentScanningResult.actionOnMatch);
            switch (contentScanningResult.sourceType) {
                case DlpResultSourceTypeEnum.Attachment:
                    contentScanningResult.sourceTypeString = settings._stringContentScanningEmailAttachment;
                    break;
                case DlpResultSourceTypeEnum.Body:
                    contentScanningResult.sourceTypeString = settings._stringContentScanningEmailContent;
                    break;
                case DlpResultSourceTypeEnum.Filename:
                    contentScanningResult.sourceTypeString = settings._stringContentScanningEmailFileName;
                    break;
                case DlpResultSourceTypeEnum.Subject:
                    contentScanningResult.sourceTypeString = settings._stringContentScanningEmailSubject;
                    break;
            }
        });

        return {
            contentScanningResultView: contentScanResults,
            actionOnMatch: currentAction
        };
    }

    public getGroupByAttachments(
        settings: SettingsView,
        groupByAttachments: GroupByAttachments,
        dlpItemResults: DlpItemResultView[]
    ): GroupByAttachments {
        dlpItemResults.forEach((item) => {
            if (item.isAttachment) {
                groupByAttachments.countTotalAttachmentsScanned++;
            }

            groupByAttachments.countTotalClientKeyword += item.clientKeywordDomainResults.length;
            item.clientKeywordDomainResults.forEach((ck) => {
                groupByAttachments.countTotalClientKeywordMatches += ck.matchCount;
            });

            groupByAttachments.countTotalContentScanning += item.contentScanResults.length;
            item.contentScanResults.forEach((cs) => {
                groupByAttachments.countTotalContentScanningMatches += cs.matchCount;
            });
        });

        groupByAttachments.groupByClientKeywordAttachments = groupByAttachments.groupByClientKeywordAttachments ? true : groupByAttachments.countTotalClientKeyword > settings._contentScanningMaxNrMatchesBeforeTryingToGroupByAttachment;
        groupByAttachments.groupByContentScanningAttachments = groupByAttachments.groupByContentScanningAttachments ? true : groupByAttachments.countTotalContentScanning > settings._contentScanningMaxNrMatchesBeforeTryingToGroupByAttachment;

        if (groupByAttachments.groupByClientKeywordAttachments) {
            groupByAttachments.groupByClientKeywordAttachmentsText = stringFormat(settings._stringContentScanningTooManyMatches, `${groupByAttachments.countTotalClientKeywordMatches}`, `${groupByAttachments.countTotalAttachmentsScanned}`);
        }

        if (groupByAttachments.groupByContentScanningAttachments) {
            groupByAttachments.groupByContentScanningAttachmentsText = stringFormat(settings._stringContentScanningTooManyMatches, `${groupByAttachments.countTotalContentScanningMatches}`, `${groupByAttachments.countTotalAttachmentsScanned}`);
        }

        return groupByAttachments;
    }
}

export const appUtils = new AppUtils();