import * as React from 'react';
import WarningMessage from './WarningMessage';
import { restClient } from '../RestClient';
import OutlookSpinner from './OutlookSpinner';
import { AppInfo } from './Backend/BackendTypes';
import { Text } from '@fluentui/react/lib/Text';
import { Icon, Image, ImageFit, Separator, SharedColors, mergeStyleSets } from '@fluentui/react';
import { systemInfo } from '../lib/Utils';

export interface LandingState {
	isWaiting: boolean;
	appInfo: AppInfo;
	logo: string | null;
	isARRAffinityActive: boolean;
	sysInfo: string;
}

const classNames = mergeStyleSets({
	notificationsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '35vw',
		justifyContent: 'center'
	},
	messageList: {
		display: 'flex column',
		width: "100%",
		marginLeft: '0px',
		marginBottom: '2vh',
		alignItems: 'center'
	},
	errorListItem: {
		display: 'flex',
		justifyContent: 'flex-start',
		color: SharedColors.red20,
		backgroundColor: "#f7d3ce",
		width: '100%',
		padding: '0.5em',

	},
	warningListItem: {
		display: 'flex',
		justifyContent: 'flex-start',
		color: SharedColors.orangeYellow20,
		backgroundColor: "#fff4ce",
		width: '100%',
		padding: '0.5em',

	},
	errorIcon: {
		fontSize: '2em',
		marginRight: '10px',
	},
	warningIcon: {
		fontSize: '2em',
		marginRight: '10px',
	},
	warningTextStyles:{
		color: SharedColors.orangeYellow20,
	},
	errorTextStyles:{
		color: SharedColors.red20,
	}
});

export default class LandingPage extends React.Component<{}, LandingState> {
	constructor(props) {
		super(props);

		this.state = {
			isWaiting: true,
			appInfo: {} as AppInfo,
			logo: null,
			isARRAffinityActive: false,
			sysInfo: "",
		};
	}

	async componentDidMount() {
		const responseAppInfo = await restClient.getAppInfo();
		const logo = await restClient.getLogo();
		const isARRAffinityActive = await restClient.getARRAffinityActive();
		const sysInfo = await systemInfo();

		await this.setState({
			isWaiting: false,
			appInfo:
				responseAppInfo.content != null
					? responseAppInfo.content
					: ({} as AppInfo),
			logo: logo.content,
			isARRAffinityActive: isARRAffinityActive.content || false,
			sysInfo: sysInfo,
		});
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = () => {
			return;
		};
	}

	render() {
		const { isWaiting, appInfo, logo, isARRAffinityActive } = this.state;

		const licenseStatusStyles = !appInfo?.licenseKeyStatus?.includes("Licensed")
			? classNames.errorTextStyles
			: "";

		return (
			<>
				{isWaiting && <OutlookSpinner message="Loading..." />}
				{!isWaiting && (
					<section className="ms-welcome__progress ms-u-fadeIn500">
						<WarningMessage message="Welcome SafeSend" />
						<header className="ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500">
							<Text variant={"xxLarge"} block>								
								<span id="app">{appInfo.product}</span> v
								<span id="version">{appInfo.version}</span>
							</Text>
							<Text id="client_version">Scripts: v5.4.5.36</Text>
						</header>
						<main className="ms-welcome__main">
							{logo && (
								<Image
									src={`data:image/png;base64,${logo}`}
									alt=""
									width={150}
									height={100}
									imageFit={ImageFit.centerContain}
								/>
							)}
							<div className={classNames.notificationsContainer}>
								{appInfo.appConfigurationErrors.isOnError && (
									<div className={classNames.messageList}>
										{
											appInfo.appConfigurationErrors.errors.map(error => {
												return (
													<div key={error.settingOnFailure} className={classNames.errorListItem}>
														<Icon iconName='ErrorBadge' className={classNames.errorIcon} />
														<div >
															<Text variant={'xLarge'} className={classNames.errorTextStyles} block>
																<span>Failed to load setting: &lt;{error.settingOnFailure}&gt;</span>
															</Text>
														</div>
													</div>
												)
											})
										}
									</div>
								)}
								{!isARRAffinityActive && (
									<div className={classNames.messageList}>
										<div className={classNames.warningListItem}>
											<Icon
												iconName="Warning"
												className={classNames.warningIcon}
											></Icon>
											<div>
												<Text
													variant={"xLarge"}
													className={classNames.warningTextStyles}
													block
												>
													<span>Warning: ARR Affinity is disabled</span>
												</Text>
											</div>
										</div>
									</div>
								)}
							</div>

							<Text variant={"xLarge"} block>
								<span>{appInfo.description}</span>
								<span> - <Text variant={'xLarge'}  className={licenseStatusStyles}>{appInfo.licenseKeyStatus}</Text></span>
							</Text>
							<br />
							<Text variant={"smallPlus"} block>
								{appInfo.copyright}
							</Text>
							<Separator styles={{ root: { width: "100%" } }} />
							<Text variant={"small"} block>
								{this.state.sysInfo}
							</Text>
						</main>
					</section>
				)}
			</>
		);
	}
}
