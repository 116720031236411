import { ITooltipProps, Stack, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import AttachmentConfirmable from './AttachmentConfirmable';
import { AttachmentDlpProps } from "./AttachmentTypes";

const tooltipProps = (files: string[], alignRightToLeft: boolean) => {
    let attachments: ITooltipProps = {
        onRenderContent: () => (
            <Stack>
                {
                    files.map((filename, index) => (
                        <AttachmentConfirmable
                            id={`${index}`}
                            key={index}
                            filename={filename}
                            alignRightToLeft={alignRightToLeft}
                            showCheckBox={false}
                            checked={false}
                            disabled={false}
                            onChange={(_checked) => { }}
                            isRemoved={false}
                        />
                    ))
                }
            </Stack>
        )
    }

    return attachments;
};

export default class AttachmentDlp extends React.Component<AttachmentDlpProps> {
    render() {
        return (
            <>
                {
                    this.props.useTooltip &&
                    <TooltipHost tooltipProps={tooltipProps(this.props.files, this.props.alignRightToLeft)}>
                        {`[${this.props.pointCursorText}]`}
                    </TooltipHost>
                }
                {
                    !this.props.useTooltip &&
                    <Stack horizontal horizontalAlign={this.props.alignRightToLeft ? "end" : "start"}>
                        {
                            this.props.files.map((filename, index) => (
                                <AttachmentConfirmable
                                    id={`${index}`}
                                    key={index}
                                    filename={filename}
                                    alignRightToLeft={this.props.alignRightToLeft}
                                    showCheckBox={false}
                                    checked={false}
                                    disabled={false}
                                    onChange={(_checked) => { }}
                                    isRemoved={false}
                                />
                                
                            ))
                        }
                    </Stack>
                }
            </>
        );
    }
}