import { RemovedAttachmentsListType } from "../OfficeDialog";

//Other API models
export interface LoginModel {
	token: string;
	licenseKeyStatus: LicenseKeyStatus;
	isDlpLicenseValid: boolean;
}

export enum TriggerEncryptionMode {
	Disabled = 0,
	AskWhenFilesAreAttached = 1,
	AskWhenFoundSensitiveContent = 2,
	AlwaysAsk = 3
}

export enum TriggerEncryptionDefaultOption {
	RequireUserInput = 0,
	Encrypt = 1,
	DoNotEncrypt = 2
}

export enum LicenseKeyStatus {
	Valid = "Valid",
	Invalid = "Invalid",
	TrialExpired = "TrialExpired",
	LicenseExpired = "LicenseExpired",
}

export enum UnprotectedAttachmentsMode {
	Ignore = 0,
	Notify = 1,
	Confirm = 2,
	Deny = 3
}

export interface EmailInfo {
	itemId: string;
	itemType: Office.MailboxEnums.ItemType | string | undefined;
	from: string | null;
	subject: string | null;
	bodyType: Office.CoercionType | string;
	body: string | null;
	recipients: EmailRecipient[];
	attachments: OutlookAttachment[] | null;
	clientLanguage: string;
	platformType: Office.PlatformType;
	connectionAddInId: string | null;
	isClientSideGetAttachmentSupported: boolean;
}

export interface EmailRecipient {
	id: number;
	emailAddress: string;
	sendType: RecipientSendType;
	isAddressTypeSupported: boolean;
	sendable: boolean;
	name: string;
	isExchangeDistributionList: boolean;
	isOutlookDistributionList: boolean;
	members: EmailRecipient[];
	recipientType: Office.MailboxEnums.RecipientType | string;
}

export interface OutlookAttachment {
	id: string;
	jsId: string;
	name: string;
	contentType: string;
	size: number;
	attachmentType: string;
	isInline: boolean;
	contentBytes: string | null;
}

export enum RecipientSendType {
	TO = "TO",
	CC = "CC",
	BCC = "BCC",
	Sender = "Sender"
};

//SignalR methods ==========================================================
export enum SignalRUIHubMethods {
	ReceiveDlpItemResults = "ReceiveDlpItemResults",
	ReceiveFinishingContentScanning = "ReceiveFinishingContentScanning",
	ReceiveAttachmentPasswordRequest = "ReceiveAttachmentPasswordRequest",
}

export enum SignalRAddInHubMethods {
	ReceiveGetAttachmentContent = "ReceiveGetAttachmentContent"
}

//SignalR models ==========================================================
export interface DlpItemResultView {
	id: string;
	isAttachment: boolean;
	status: DlpItemStatus;
	statusString: string;
	containsUnprotectedAttachment: boolean;
	contentScanResults: ContentScanningResultView[];
	clientKeywordDomainResults: ClientKeywordDomainResultView[];
}

export interface DlpResultView {
	key: string;
	sourceType: DlpResultSourceTypeEnum;
	sourceTypeString: string;
	attachmentPath: string;
	matchCount: number;
	clientKeyword: string;
	firstMatch: string;
	files: string[];
	groupBy: boolean;
	useTooltip: boolean;
	tooLong: boolean;
}

export interface ContentScanningResultView extends DlpResultView {
	ruleName: string;
	actionOnMatch: ActionOnMatch;
}

export interface ClientKeywordDomainResultView extends DlpResultView {
	allowedDomain: string;
}

export enum DlpResultSourceTypeEnum {
	Filename = "Filename",
	Attachment = "Attachment",
	Body = "Body",
	Subject = "Subject"
}

export enum ActionOnMatch {
	Confirm = "Confirm",
	Inform = "Inform",
	Deny = "Deny",
	ConfirmText = "ConfirmText"
}

export enum DlpItemStatus {
	Unsupported = "Unsupported",
	Scanning = "Scanning",
	Error = "Error",
	ProtectedDocument = "ProtectedDocument",
	PasswordProtected = "PasswordProtected",
	SensitiveDataFound = "SensitiveDataFound",
	Success = "Success",
	Unknown = "Unknown",
	ScanNotStarted = "ScanNotStarted",
	ScanTimedOut = "ScanTimedOut"
}

//Backend API response ==========================================================
export interface AppInfo {
	product: string;
	version: string;
	description: string;
	copyright: string;
	licenseKeyStatus: string;
	appConfigurationErrors: AppConfigurationErrors
}

interface AppConfigurationErrors {
	errors: AppConfigurationError[],
	isOnError: boolean,
}

interface AppConfigurationError{
	settingOnFailure: string,
	errorMessage: string,
}

export interface PlatformConfig {
	enabled: boolean;
	attachmentSizeThreshold: number;
	isOffice365: boolean;
	retryOnServerConnectionReset: number;
	debugLogging: boolean;
}

export interface SettingsView {
	emailProviderUrl: string;
	_unresolvedRecipientRetryCount: number;
	_unresolvedRecipientRetryInitialDelay: number;
	_useDomainColorCoding: boolean;
	_useLocalizedLanguage: boolean;
	_useLocalizedLanguageGPOOverrideLanguage: string;
	_useLocalizedLanguageForceLanguage: string;
	_localizationXlsxPath: string;
	_useSafeDomainsAsBlacklist: boolean;
	_useQuickLookupForOutlookContacts: boolean;
	_warnUserIfMoreThanXEmailsInThread: number;
	_DLPKeyStatusStringValid: string;
	_DLPKeyStatusStringInvalid: string;
	_logo: string;
	_logoFilePath: string;
	logoBytes: string;
	_windowMinimumHeight: number;
	_windowMinimumWidth: number;
	_version: string;
	_triggerEncryptionXHeader: string;
	_triggerEncryptionSubjectString: string;
	_treatExternalExchangeDLAsUnsafe: boolean;
	_treatMatchingEmailsAsExternal: string;
	_treatMatchingExchangeDLNamesAsExternal: string;
	_treatMatchingEmailsAsUnsafe: string;
	_treatMatchingExchangeDLNamesAsUnsafe: string;
	_treatMatchingUnresolvedRecipientsAsInternal: string;
	_treatUnmatchedGALEntriesAsSafe: boolean;
	_contentScanningMaxNrMatchesBeforeTryingToGroupByAttachment: number;
	_contentScanningTimeoutMS: number;
	_debugLogging: boolean;
	_debugLoggingCopyADXLoaderLog: boolean;
	_detectBulkEmailing: boolean;
	_detectBulkEmailingDetectionPeriod: number;
	_disableConfirmAttachments: boolean;
	_disableConfirmationForTheseSendingEmailAddresses: string;
	_confirmReplyToRecipients: boolean;
	_disableInternalSave: boolean;
	_disableOpenAttachmentsLink: boolean;
	_disableSelectAll: boolean;
	_disableSelectAllForLessThanXCheckboxes: number;
	_dlpScanSequential: boolean;
	_expandListsByDefault: boolean;
	_enableRecipientAttachmentRemoval: boolean;
	_forceWindowTopMost: boolean;
	_footerEnabled: boolean;
	_footerText: string;
	_disableMarkMsgFilesAsAttachments: boolean;
	_groupByDomain: boolean;
	_confirmExternalRecipients: boolean;
	_classificationXHeaderValues: string;
	_debugSimulateUnresolvedExchangeRecipients: boolean;
	_debugShowLanguageSelector: boolean;
	_confirmAndSendOnCtrlEnter: boolean;
	_debugSimulateUncaughtException: boolean;
	_addXHeader: boolean;
	_BCCWarningIfMoreThanX_TOCC_Recipients: number;
	_checkAutoForwardedEmails: boolean;
	_checkRepliesToExternalMeetings: boolean;
	_clientKeywordDisableConfirm: boolean;
	_classificationXHeader: string;
	_debugSimulateClassificationXHeader: string;
	_hideRecipientDisplayName: boolean;
	_loggingFileLogMaxSizeMB: number;
	_resolveWithAddressBook: boolean;
	_selectAllPositionOnBottom: boolean;
	_showAttachmentsSeparator: boolean;
	_showFromAddress: boolean;
	_sortRecipientsAndFiles: boolean;
	_resolveDLEmail: boolean;
	_loggingFileLogEnabled: boolean;
	_readSettingsEveryXMinutes: number;
	_loggingWindowsEventLogEnabled: boolean;
	_loggingEventTypeFilter: number;
	_loggingSyslogHostname: string;
	_loggingSyslogPort: number;
	_loggingSyslogUseStrictRFC5424Format: boolean;
	_markEmbeddedImagesAsAttachments: boolean;
	_markEmbeddedImagesAsAttachmentsFileSizeLimitKB: number;
	_onlyPopupForAttachments: boolean;
	_onlyPopupForAttachmentsOrDLPMatch: boolean;
	_onlyPopupForNewEmailThreads: boolean;
	_onlyPopupForNewEmailThreadsDaysOfHistory: number;
	_onlyPopupForDLPMatch: boolean;
	_checkDistributionListMembers: boolean;
	_policyLink: string;
	_precheckRecipients: boolean;
	_processMaxNrRecipients: number;
	_onlyPopupForMultipleExternalDomains: boolean;
	_triggerEncryptionMode: number;
	_triggerEncryptionDefaultOption: number;
	_unprotectedAttachmentsMode: number;

	_stringAttachmentStatusError: string;
	_stringAttachmentStatusPasswordProtected: string;
	_stringAttachmentStatusProtectedDocument: string;
	_stringAttachmentStatusScanning: string;
	_stringAttachmentStatusScanNotStarted: string;
	_stringAttachmentStatusScanTimedOut: string;
	_stringAttachmentStatusSensitiveDataFound: string;
	_stringAttachmentStatusSuccess: string;
	_stringAttachmentStatusUnknown: string;
	_stringAttachmentStatusUnsupported: string;
	_stringBulkEmailQuestion: string;
	_stringContentScanningAllowedDomains: string;
	_stringContentScanningClientData: string;
	_stringContentScanningComplete: string;
	_stringContentScanningConfirmContent: string;
	_stringContentScanningConfirmContentText: string;
	_stringContentScanningEmailAttachment: string;
	_stringContentScanningEmailContent: string;
	_stringContentScanningEmailFileName: string;
	_stringContentScanningEmailSubject: string;
	_stringContentScanningFirstMatch: string;
	_stringContentScanningPointCursorHere: string;
	_stringContentScanningReleaseWindow: string;
	_stringContentScanningRule: string;
	_stringContentScanningScanningContent: string;
	_stringContentScanningSendingNotAllowed: string;
	_stringContentScanningSensitiveContentFound: string;
	_stringContentScanningSource: string;
	_stringContentScanningTimedOutLong: string;
	_stringContentScanningTimedOutShort: string;
	_stringContentScanningTooLongToDisplay: string;
	_stringContentScanningTooManyMatches: string;
	_stringContentScanningTotalMatches: string;
	_stringContentScanningWrongClientWarning: string;
	_stringContentScanningZipHeader: string;
	_stringErrorMessage: string;
	_stringErrorTitle: string;
	_stringFileSizeBytes: string;
	_stringFileSizeKB: string;
	_stringFileSizeMB: string;
	_stringForbiddenRecipient: string;
	_stringForbiddenRecipients: string;
	_stringForbiddenRecipientTitle: string;
	_stringMainAllRecipientsNumber: string;
	_stringMainBCCWarning: string;
	_stringMainCancelButton: string;
	_stringMainEmailBCC: string;
	_stringMainEmailCC: string;
	_stringMainEmailFrom: string;
	_stringMainEmailTO: string;
	_stringMainEmailReplyTo: string;
	_stringMainFileAttachedConfirmed: string;
	_stringMainFilesAttached: string;
	_stringMainFilesAttachedConfirmed: string;
	_stringMainFilesAttachedHeader: string;
	_stringMainFilesAttachedOpen: string;
	_stringMainFilesAttachedOpenFailed: string;
	_stringMainListIdentifier: string;
	_stringMainMessageManyRecipients: string;
	_stringMainMessageMultipleDomainRecipients: string;
	_stringMainMessageMultipleRecipients: string;
	_stringMainMessageSingleDomainRecipient: string;
	_stringMainMessageSingleRecipient: string;
	_stringMainPolicy: string;
	_stringMainSelectAll: string;
	_stringMainSelectedRecipients: string;
	_stringMainSendButton: string;
	_stringMainSubject: string;
	_stringMainTitleUnsafeClassified: string;
	_stringMainTriggerEncryptionOptionYes: string;
	_stringMainTriggerEncryptionQuestion: string;
	_stringMainTitleExternal: string;
	_stringMainTitleExternalClassified: string;
	_stringMainTitleUnsafe: string;
	_stringMainTriggerEncryptionOptionNo: string;
	_stringMainUnresolvableIdentifier: string;
	_stringNotEncryptedAttachmentConfirm: string;
	_stringNotEncryptedAttachmentDeny: string;
	_stringNotEncryptedAttachmentNotify: string;
	_stringNotEncryptedAttachmentsConfirm: string;
	_stringNotEncryptedAttachmentsDeny: string;
	_stringNotEncryptedAttachmentsNotify: string;
	_stringNotifyMatchingRecipientsRegexMatched: string;
	_stringPasswordRequired: string;
	_stringPasswordSubmit: string;
	_stringReplyAllBlock: string;
	_stringReplyAllTitle: string;
	_stringReplyAllWarning: string;
	_stringReplyAllWarningDistributionList: string;
	_stringUnexpectedErrorCancel: string;
	_stringUnexpectedErrorMessage: string;
	_stringUnexpectedErrorSendAnyway: string;
	_stringWarnUserIfLongEmailThreadMessage: string;
	_stringWarnUserIfLongEmailThreadTitle: string;
}

export interface ApiResponseView {
	unsafeRecipientsCount: number;
	unsafeAttachmentsCount: number;
	fromAddress: string;
	subjectText: string;
	alignRightToLeft: boolean;
	attachmentPasswordRequested: boolean;
	onSendProcessContex: OnSendProcessContextView;
	onSendPostProcessContext: OnSendPostProcessContextView;
	unsafeRecipients: UnsafeRecipientsView;
	unsafeAttachments: AttachmentView[];
	dlpScanResults: DlpItemResultView[];
	noInsecureItemsFound: boolean;
	hasSafeRecipients: boolean;
	hasDistributionLists: boolean;
}

export interface OnSendPostProcessContextView {
	shouldCancelSendEvent: boolean;
	footerText: string;
	subjectPrefix: string;
}

export interface OnSendProcessContextView {
	id: string; 
	isLicensed: boolean;
	isDlpLicenseValid: boolean;
	confirmationRequired: boolean;
	popupForMatchingRecipientsRegexMatched: boolean;
	scanStarted: boolean;
	scanCompleted: boolean;
	scanTimedOut: boolean;
	hasForbiddenRecipients: boolean;
	sendCanceled: boolean;
	mailItem: MailItemView;
	confirmationFlags: ConfirmationFlagsView;
	settings: SettingsView;
	forbiddenRecipients: UnsafeRecipientView[];
	bulkSendDetectect: boolean;
	bulkSendSupressed: boolean;
	auditStringMailItemMeta: string; 
	auditStringDlpResults: string;
	hasUnsafeRecipients: boolean;
	filesReviewedCount: number;
}

export interface MailItemView {
	itemId: string;
	attachmentCount: number;
	subject: string;
	hasHtmlBody: boolean;
	htmlBody: string;
	body: string;
	itemType: MailItemTypeEnum;
}

export interface AttachmentView {
	id: string;
	filename: string;
	size: number;
	contentType: string;
}

export interface ConfirmationFlagsView {
	showBccWarning: boolean;
	showEncryptPrompt: boolean;
	showFromAddress: boolean;
	showSelectAll: boolean;
	showRecipientAttachmentSeparator: boolean;
	showAttachmentLinks: boolean;
	showPolicyText: boolean;
	showTrialSettings: boolean;
	colorDomains: boolean;
	displaySubject: boolean;
	showAttachments: boolean;
	showLanguageSelector: boolean;
	mainTitle: string;
}

export interface UnsafeRecipientsView {
	isGroupByDomain: boolean;
	toGroup: UnsafeGroupRecipientsView[];
	ccGroup: UnsafeGroupRecipientsView[];
	bccGroup: UnsafeGroupRecipientsView[];
	to: UnsafeRecipientView[];
	cc: UnsafeRecipientView[];
	bcc: UnsafeRecipientView[];
}

export interface UnsafeGroupRecipientsView {
	id: string;
	domain: string;
	recipients: UnsafeRecipientView[];
}

export interface UnsafeRecipientView {
	id: string;
	displayName: string;
	name: string;
	email: string;
	domain: string;
	isList: boolean;
	tooManyRecipientsCount: number;
}

export interface ConfirmInfo {
	//From OnSendProcessContextView
	itemId: string;
	mailItemType: MailItemTypeEnum;
	hasHtmlBody: boolean;
	id: string;
	confirmationRequired: boolean;
	hasForbiddenRecipients: boolean;
	bulkSendDetectect: boolean;
	bulkSendSupressed: boolean;
	confirmationFlags: ConfirmationFlagsView;
	auditStringMailItemMeta: string;
	auditStringDlpResults: string;
	hasUnsafeRecipients: boolean;
	filesReviewedCount: number;
	//From confirmation dialog
	clientLanguage: string;
	sendCanceled: boolean;
	sendConfirmed: boolean;
	shouldTriggerEncryption: boolean;
	showEncryptPrompt: boolean;
	removedRecipientsList?: Array<string>;
	removedAttachmentsList?: Array<RemovedAttachmentsListType>;
}

export enum MailItemTypeEnum {
	Unknown = "Unknown",
	Mail = "Mail",
	TaskRequest = "TaskRequest",
	Meeting = "Meeting"
}