/* global Office */  //Required for this to be found.  see: https://github.com/OfficeDev/office-js-docs-pr/issues/691
//import 'office-js';
import * as signalR from '@microsoft/signalr';
import { notificationLogger } from './NotificationMessage';
import { Modules } from '../LogConfiguration';
import { LogLevel, safeSendLogging } from './Logger';
import { ConnectionError, restClient, UnauthorizedError } from '../RestClient';
import { ApiResponseView, ConfirmInfo, EmailInfo, EmailRecipient, LicenseKeyStatus, LoginModel, OnSendPostProcessContextView, OutlookAttachment, PlatformConfig, RecipientSendType, SignalRAddInHubMethods } from '../components/Backend/BackendTypes';
import { SignalRHubType, StorageDatatype, storageUtils } from './Utils';
import { DialogValues, RemovedRecipientsListType } from '../components/OfficeDialog';
import { LoginResult, LoginResultStatus } from '../components/Login/LoginTypes';

let logger = safeSendLogging.getLogger(Modules.SafeSend_AddIn);
var globalMailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
var globalAppInfo: { host: Office.HostType, platform: Office.PlatformType };
var globalEmailData: IEmailSettings;
var globalSendEvent: any;
var globalAddInDialogInstance: Office.Dialog | null = null;
var globalLoginDialogInstance: Office.Dialog | null = null;
var globalRetryAttempt: number = 0;
var globalMaxRetryAttempt: number = 1;
var globalApiResponseView: ApiResponseView;
var globalIsCurrentWorkflowRestarting = false;
var globalPlatformConfig: PlatformConfig = {
	attachmentSizeThreshold: 0,
	enabled: false,
	isOffice365: false,
	retryOnServerConnectionReset: 0,
	debugLogging: false
}

//Initialization of the Office.js library
Office.onReady(function (info) {
	if (Office.context.mailbox === undefined) {
		return;
	}

	globalAppInfo = info;
	globalMailboxItem = Office.context.mailbox.item;
	notificationLogger.infoReplace(globalMailboxItem, "i1", "Initialized");
});

interface IEmailSettings {
	itemId: string,
	outlookToken: string;
}

export function setSubjectAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	newSubject: string,
): Promise<void> {
	return new Promise((resolve, reject) => {
		mailboxItem?.subject.setAsync(newSubject, { asyncContext: {} }, (asyncResult) => {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				logger.error(asyncResult.error);
				reject(asyncResult.error);
			}

			resolve();
		});
	});
}

export function setBodyAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	newBody: string,
	coercionType: Office.CoercionType
): Promise<void> {
	return new Promise((resolve, reject) => {
		mailboxItem?.body.setAsync(newBody, { coercionType: coercionType, asyncContext: {} }, (asyncResult) => {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}

			resolve();
		});
	});
}

export async function processOnSendEventLog(sendEvent) {
	logger.setMinLevel(LogLevel.TRACE);
	if (!storageUtils.localExists(StorageDatatype.Log)) {
		storageUtils.localSet(StorageDatatype.Log, "");
	}

	await processOnSend(sendEvent);
}

export async function processOnSendEvent(sendEvent) {
	if (storageUtils.localExists(StorageDatatype.Log)) {
		storageUtils.localRemove(StorageDatatype.Log);
		logger = safeSendLogging.getLogger(Modules.SafeSend_AddIn);
	}
	
	await processOnSend(sendEvent);
}

export async function processOnSend(sendEvent) {
	try {
		globalSendEvent = sendEvent;
		let dialogId = Math.random().toString(36).substr(2, 9);
		storageUtils.localSet(StorageDatatype.DialogId, dialogId);
		logger.debug("1. On-Send throwed");

		let responsePlatform = await restClient.getPlatformInfo(globalAppInfo.platform, Office.context.displayLanguage);
		if (responsePlatform.content == null) {
			logger.info(`responsePlatform.content is null`);
			return;
		}

		globalPlatformConfig = responsePlatform.content;
		if (!globalPlatformConfig?.enabled) {
			logger.info(`${globalAppInfo.platform} platform disabled`);
			processSendEmail(sendEvent);
			return;
		}

		if (!storageUtils.localExists(StorageDatatype.Log)) {
			if (globalPlatformConfig.debugLogging) {
				safeSendLogging.setMinLevel(LogLevel.DEBUG);
				logger.setMinLevel(LogLevel.DEBUG);
			}
		}

		notificationLogger.infoReplace(globalMailboxItem, "i1", "Verifying...");
		globalEmailData = await saveItemAndGetOutlookTokenAsync(responsePlatform.content.isOffice365, globalMailboxItem);
		let success = true;
		if (responsePlatform.content.isOffice365) {
			try {
				globalEmailData.outlookToken = await getOutlookTokenAsync();
				logger.info("Authenticating...");
			} catch (e) {
				logger.error(e);
				success = false;
				if (storageUtils.localExists(StorageDatatype.OutlookAccessToken)) {
					let outlookAccesToken: string = storageUtils.localGet(StorageDatatype.OutlookAccessToken);
					var splits = outlookAccesToken.split(".");
					let expiration: number = JSON.parse(atob(splits[1])).exp;
					let current: number = Math.round(new Date().getTime() / 1000);
					if (current < expiration) {
						logger.info("Authenticating (cache)...");
						globalEmailData.outlookToken = outlookAccesToken;
						await processEmail();
					} else {
						logger.info("Authenticating (dialog)...");
						showLoginDialog();
					}
				} else {
					showLoginDialog();
				}
			}
		}

		if (success) {
			await processEmail();
		}
	} catch (e) {
		logger.error(e);
		if (e instanceof UnauthorizedError) {
			await workflowOnConnectionError();
		} else {
			await onConnectionError();
		}
	}
}

export async function getSignalRConnection(authToken: string, signalRHubType: SignalRHubType): Promise<signalR.HubConnection> {
	var connection = new signalR.HubConnectionBuilder().withUrl(signalRHubType, { accessTokenFactory: () => authToken }).build();
	await connection.start();
	logger.info(`${signalRHubType} communication started`);

	return connection;
}

export async function workflowOnConnectionError() {
	if (globalIsCurrentWorkflowRestarting) {
		logger.info("Current workflow is restarting...");
		return;
	} else {
		globalIsCurrentWorkflowRestarting = true;
		await onConnectionError();
	}
}

async function onConnectionError() {
	logger.debug("Entered onConnectionError()");
	let isAddInDialogOpen = globalAddInDialogInstance !== null;
	tryCloseDialogs();
	if (globalRetryAttempt < globalMaxRetryAttempt) {
		switch (globalPlatformConfig.retryOnServerConnectionReset) {
			case 0:
				sendingEmailBecauseOfError();
				break;
			case 1:
				if (isAddInDialogOpen) {
					sendingEmailBecauseOfError();
				} else {
					await retryConnection();
				}
				break;
			case 2:
				await retryConnection();
				break;
		}
	} else {
		sendingEmailBecauseOfError();
	}
}

async function retryConnection() {
	globalRetryAttempt++;
	logger.warn(`Connectivity issues, retry connection attempt: (${globalRetryAttempt} of ${globalMaxRetryAttempt})`);
	notificationLogger.remove(globalMailboxItem, "i1");
	notificationLogger.progressReplace(globalMailboxItem, "p1", "We are experiencing connectivity issues. Retrying.");
	await processOnSendEvent(globalSendEvent);
}

function tryCloseDialogs() {
	if (globalLoginDialogInstance !== null) {
		globalLoginDialogInstance.close();
		globalLoginDialogInstance = null;
	}

	if (globalAddInDialogInstance !== null) {
		globalAddInDialogInstance.close();
		globalAddInDialogInstance = null;
	}
}

function showLoginDialog() {
	Office.context.ui.displayDialogAsync(`${window.location.origin}/login`, { height: 60, width: 30 }, loginDialogCallback);
}

function loginDialogCallback(asyncResult: Office.AsyncResult<Office.Dialog>) {
	if (asyncResult.status === Office.AsyncResultStatus.Failed) {
		switch (asyncResult.error.code) {
			case 12004:
				logger.info("Domain is not trusted");
				break;
			case 12005:
				logger.info("HTTPS is required");
				break;
			case 12007:
				logger.info("A dialog is already opened.");
				break;
			default:
				logger.error(asyncResult.error);
				break;
		}

		notificationLogger.remove(globalMailboxItem, "i1");
		notificationLogger.errorReplace(globalMailboxItem, "e1", "Unable to successfully authenticate user");
		processSendEmail(globalSendEvent, false);
	}
	else {
		globalLoginDialogInstance = asyncResult.value;
		globalLoginDialogInstance.addEventHandler(Office.EventType.DialogMessageReceived, loginDialogEventHandler);
		globalLoginDialogInstance.addEventHandler(Office.EventType.DialogEventReceived, loginDialogEventHandler);
	}
}

async function loginDialogEventHandler(arg) {
	globalLoginDialogInstance?.close();
	let isError: boolean = arg.error	
	switch (arg.error) {
		case 12002:
			logger.warn("Cannot load URL, no such page or bad URL syntax.");
			break;
		case 12003:
			logger.warn("HTTPS is required.");
			break;
		case 12006:
			// The dialog was closed, typically because the user the pressed X button.
			logger.info("Dialog closed by user");
			break;
		default:
			break;
	}

	if (isError) {
		notificationLogger.remove(globalMailboxItem, "i1");
		notificationLogger.errorReplace(globalMailboxItem, "e1", "Unable to successfully authenticate user");
		processSendEmail(globalSendEvent, false);
	} else {
		let loginResults = JSON.parse(arg.message) as LoginResult;
		if (loginResults.status === LoginResultStatus.Succeeded) {
			globalEmailData.outlookToken = loginResults.accessToken
			storageUtils.localSet(StorageDatatype.OutlookAccessToken, globalEmailData.outlookToken);

			await processEmail();
		} else {
			logger.error(loginResults.error);
			notificationLogger.remove(globalMailboxItem, "i1");
			notificationLogger.errorReplace(globalMailboxItem, "e1", "Unable to successfully authenticate user");
			processSendEmail(globalSendEvent, false);
		}
	}
}

async function processEmail() {
	let login = await getAuthenticationToken(globalSendEvent, globalEmailData.outlookToken);
	if (login == null) {
		return;
	}
	storageUtils.localSet(StorageDatatype.SafeSendToken, login.token);

	let isClientSideGetAttachmentSupported = isSetSupported("1.8");
	let emailInfo = await getEmailInfo(globalEmailData.itemId, isClientSideGetAttachmentSupported, globalAppInfo.platform);
	if (isClientSideGetAttachmentSupported && login.isDlpLicenseValid && emailInfo.attachments !== null && emailInfo.attachments.length > 0) {
		try {
			let addInSignalRConnection = await getSignalRConnection(login.token, SignalRHubType.AddinHub);
			emailInfo.connectionAddInId = addInSignalRConnection.connectionId;
			await configureAddInSignalR(globalMailboxItem, login.token, addInSignalRConnection);
		} catch (error) {
			throw new ConnectionError(`${SignalRHubType.AddinHub} error: ${JSON.stringify(error)}`);
		}
	}

	let responseMail = await restClient.processMail(login.token, emailInfo);
	if (responseMail.content === null) {
		processSendEmail(globalSendEvent);
		return;
	}

	globalApiResponseView = responseMail.content
	showAddInDialog(globalMailboxItem, globalApiResponseView);
}

async function showAddInDialog(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	apiResponse: ApiResponseView
) {
	logger.debug("2. Add-In callback");
	if (apiResponse.onSendProcessContex?.hasForbiddenRecipients) {
		notificationLogger.infoReplace(mailboxItem, "i1", 'Forbidden recipients found');
	}
	else {
		if (apiResponse.noInsecureItemsFound) {
			storageUtils.localRemove(StorageDatatype.ApiResponse);
			await postProcessTasks(mailboxItem, apiResponse.onSendPostProcessContext);
			logger.info("Email sent, no unsafe items found");
			processSendEmail(globalSendEvent, true);
			return;
		} 

		notificationLogger.infoReplace(mailboxItem, "i1", 'External recipients found');
	}

	storageUtils.localSet(StorageDatatype.ApiResponse, JSON.stringify(apiResponse));

	logger.debug(`3. Show SafeSend dialog`);
	Office.context.ui.displayDialogAsync(window.location.origin + "/dialog", getAddInDialogOptions(apiResponse), addInDialogCallback)
}

function getAddInDialogOptions(apiResponse: ApiResponseView): Office.DialogOptions {
	let maxWidth = 80;
	let maxHeight = 70;
	let forbiddenRecipientsLength = apiResponse.onSendProcessContex?.forbiddenRecipients?.length;
	if (forbiddenRecipientsLength > 0) {
		maxWidth = 40;
		maxHeight = forbiddenRecipientsLength > 10 ? 40 : forbiddenRecipientsLength * 3;
		maxHeight = maxHeight + 25;
	}

	return {
		asyncContext: apiResponse,
		displayInIframe: true,
		height: maxHeight,
		promptBeforeOpen: false,
		width: maxWidth
	}
}

function addInDialogCallback(asyncResult: Office.AsyncResult<Office.Dialog>) {
	logger.debug("4. SafeSend dialog callback");
	if (asyncResult.status === Office.AsyncResultStatus.Failed) {
		// In addition to general system errors, there are 3 specific errors for 
		// displayDialogAsync that you can handle individually.
		switch (asyncResult.error.code) {
			case 12004:
				logger.info("Domain is not trusted");
				break;
			case 12005:
				logger.info("HTTPS is required");
				break;
			case 12007:
				logger.info("A dialog is already opened.");
				break;
			default:
				logger.error(asyncResult.error.message);
				break;
		}
	}
	else {
		globalAddInDialogInstance = asyncResult.value;
		globalApiResponseView = asyncResult.asyncContext as ApiResponseView;

		/*Messages are sent by developers programatically from the dialog using office.context.ui.messageParent(...)*/
		globalAddInDialogInstance.addEventHandler(Office.EventType.DialogMessageReceived, addInDialogEventHandler);

		/*Events are sent by the platform in response to user actions or errors. For example, the dialog is closed via the 'x' button*/
		globalAddInDialogInstance.addEventHandler(Office.EventType.DialogEventReceived, addInDialogEventHandler);
	}
}

async function addInDialogEventHandler(arg) {
	logger.debug("5. SafeSend dialog event handler");
	globalAddInDialogInstance?.close();
	let authToken: string = storageUtils.localGet(StorageDatatype.SafeSendToken) as string;
	// In addition to general system errors, there are 2 specific errors 
	// and one event that you can handle individually.
	switch (arg.error) {
		case 12002:
			logger.warn("Cannot load URL, no such page or bad URL syntax.");
			break;
		case 12003:
			logger.warn("HTTPS is required.");
			break;
		case 12006:
			// The dialog was closed, typically because the user the pressed X button.
			logger.info("Dialog closed by user");
			try {
				await postProcess(globalMailboxItem, globalSendEvent, authToken, { send: false, encrypt: false, showEncryptPrompt: false });
			} catch (e) {
				logger.error(e);
				sendingEmailBecauseOfError();
			}
			break;
		default:
			try {
				let dialogValues = JSON.parse(arg.message) as DialogValues;
				await postProcess(globalMailboxItem, globalSendEvent, authToken, dialogValues);
			} catch (e) {
				logger.error(e);
				sendingEmailBecauseOfError();
			}
			break;
	}
}

async function postProcess(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	sendEvent: any,
	authToken: string,
	dialogValues: DialogValues
): Promise<void> {
	notificationLogger.remove(mailboxItem, "i1");
	let confirmInfo = getConfirmInfo(dialogValues);
	let sent = false;
	try {
			if (dialogValues.send) {
			dialogValues.removedRecipientsList && await removeRecipients(dialogValues.removedRecipientsList);
			dialogValues.removedAttachmentsList && await removeAttachments(dialogValues.removedAttachmentsList.map(elem=> elem.id));
			confirmInfo.shouldTriggerEncryption && await setSubjectPrefix(mailboxItem);
			sent = true;

			notificationLogger.progressReplace(mailboxItem, "i1", "Sending the email.");			
			let response = await restClient.postProcess(authToken, confirmInfo);
			let onSendPostProcessContextView = response.content;
			if(onSendPostProcessContextView?.shouldCancelSendEvent){
				sent = false;	
			}

			await postProcessTasks(
				mailboxItem,
				onSendPostProcessContextView
			);
		}
	 } catch (error) {
			logger.error(error);
		}
		finally {
			if(!sent) {
				try {
					confirmInfo.removedAttachmentsList = [];
					confirmInfo.removedRecipientsList = [];
					confirmInfo.sendConfirmed = false;
					let response = await restClient.postProcess(authToken, confirmInfo);
					if (!response.isSuccessStatusCode) {
						logger.error(response.errorMessage);
					}
				} catch (error) {
					logger.error(error);
				}	
			}
			processSendEmail(sendEvent, sent);
		}	
	}
	

//logic inside of setSubjectPrefix is a duplicate of what we do in CORE to set subjectprefix
//when shouldTriggerEncryption is true;
async function setSubjectPrefix(
	mailboxItem:
		| (Office.Item &
				Office.ItemCompose &
				Office.ItemRead &
				Office.Message &
				Office.MessageCompose &
				Office.MessageRead &
				Office.Appointment &
				Office.AppointmentCompose &
				Office.AppointmentRead)
		| undefined
) {
	const { _triggerEncryptionSubjectString: subjectPrefix } =
		globalApiResponseView.onSendProcessContex.settings;

	if (subjectPrefix) {
		try {
			await setSubjectAsync(
				mailboxItem,
				`${subjectPrefix} ${globalApiResponseView.onSendProcessContex.mailItem.subject}`
			);
			logger.debug("Prefixed subject with ", subjectPrefix);
		} catch (error) {
			logger.debug("Failed to prefix the subject for encryption");
			throw error;
		}
	}
}

async function postProcessTasks(
	mailboxItem: (
		Office.Item 
		& Office.ItemCompose 
		& Office.ItemRead 
		& Office.Message 
		& Office.MessageCompose 
		& Office.MessageRead 
		& Office.Appointment 
		& Office.AppointmentCompose 
		& Office.AppointmentRead
		) | undefined,
	onSendPostProcessContextView: OnSendPostProcessContextView | null
): Promise<void> {
	if (!onSendPostProcessContextView)
	{
		return;
	}
	
	if (!onSendPostProcessContextView.shouldCancelSendEvent) {
		if (globalApiResponseView.onSendProcessContex.settings._footerEnabled
			&& onSendPostProcessContextView.footerText !== null
			&& (globalAppInfo.platform === Office.PlatformType.OfficeOnline || globalAppInfo.platform === Office.PlatformType.PC)) {

			if (globalApiResponseView.onSendProcessContex.mailItem.hasHtmlBody) {
				let htmlBody: string = globalApiResponseView.onSendProcessContex.mailItem.htmlBody;
				if (htmlBody.includes("<body>")) {
					onSendPostProcessContextView.footerText += "</body>";
					htmlBody = htmlBody.replace("</body>", onSendPostProcessContextView.footerText);
				} else {
					htmlBody += onSendPostProcessContextView.footerText;
				}

				await setBodyAsync(mailboxItem, htmlBody, Office.CoercionType.Html);
			} else {
				let textBody: string = globalApiResponseView.onSendProcessContex.mailItem.body;
				textBody += onSendPostProcessContextView.footerText;
				await setBodyAsync(mailboxItem, textBody, Office.CoercionType.Text);
			}
		}
	}
}

function getConfirmInfo(dialogValues: DialogValues): ConfirmInfo {
	let context = globalApiResponseView.onSendProcessContex;
	let removedRecipients: string[] = [];
	dialogValues.removedRecipientsList?.forEach(elem => {
		removedRecipients = [...removedRecipients, ...elem.recipients];
	});
	removedRecipients = [...new Set(removedRecipients)];
	let removedAttachments = dialogValues.removedAttachmentsList;

	let confirmInfo: ConfirmInfo = {
		itemId: context.mailItem.itemId,
		mailItemType: context.mailItem.itemType,
		hasHtmlBody: context.mailItem.hasHtmlBody,
		id: context.id,
		confirmationRequired: context.confirmationRequired,
		hasForbiddenRecipients: context.hasForbiddenRecipients,
		bulkSendDetectect: context.bulkSendDetectect,
		bulkSendSupressed: context.bulkSendSupressed,
		confirmationFlags: context.confirmationFlags,
		auditStringDlpResults: context.auditStringDlpResults,
		auditStringMailItemMeta: context.auditStringMailItemMeta,
		filesReviewedCount: context.filesReviewedCount,
		hasUnsafeRecipients: context.hasUnsafeRecipients,
		clientLanguage: Office.context.displayLanguage,
		sendCanceled: !dialogValues.send,
		sendConfirmed: dialogValues.send,
		shouldTriggerEncryption: dialogValues.encrypt,
		showEncryptPrompt: dialogValues.showEncryptPrompt,
		removedRecipientsList: removedRecipients,
		removedAttachmentsList: removedAttachments
	}

	if (storageUtils.localExists(StorageDatatype.AuditStringDlpResults)) {
		confirmInfo.auditStringDlpResults = storageUtils.localGet(StorageDatatype.AuditStringDlpResults) as string;
		storageUtils.localRemove(StorageDatatype.AuditStringDlpResults);
	}

	return confirmInfo;
}

function configureAddInSignalR(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	authToken: string,
	connection: signalR.HubConnection
): void {
	connection.onclose(async error => {
		logger.error(`${SignalRHubType.AddinHub} error:`, error);
		await workflowOnConnectionError();
	});

	connection.on(SignalRAddInHubMethods.ReceiveGetAttachmentContent, async (attachment: OutlookAttachment) => {
		logger.debug(`${SignalRAddInHubMethods.ReceiveGetAttachmentContent} "${attachment.name}"`);
		try {
			let contentBytes = await getAttachmentContentAsync(mailboxItem, attachment);
			let b64 = isBase64(contentBytes);

			if (b64) {
				attachment.contentBytes = contentBytes;
			} else {
				attachment.contentBytes = btoa(contentBytes);
				if (attachment.attachmentType === Office.MailboxEnums.AttachmentType.Item) {
					attachment.name += ".eml";
				}
			}
		} catch (e) {
			logger.error("getAttachmentContentAsync error: ", e);
		}

		await restClient.sendAttachmentContent(authToken, attachment);
	});
}

function sendingEmailBecauseOfError() {
	let message = "We are experiencing connectivity issues. Sending the email.";
	logger.error(message);
	notificationLogger.remove(globalMailboxItem, "i1");
	notificationLogger.remove(globalMailboxItem, "p1");
	notificationLogger.errorReplace(globalMailboxItem, "e1", message);
	setTimeout(() => { processSendEmail(globalSendEvent, true); }, 3000);
}

async function getEmailInfo(itemId: string, isClientSideGetAttachmentSupported: boolean, platformType: Office.PlatformType): Promise<EmailInfo> {
	notificationLogger.infoReplace(globalMailboxItem, "i1", "Validating email...");
	let emailInfo: EmailInfo = {
		itemId: itemId,
		itemType: globalMailboxItem?.itemType,
		from: null,
		subject: null,
		bodyType: "",
		body: null,
		recipients: [],
		attachments: null,
		clientLanguage: Office.context.displayLanguage,
		platformType: platformType,
		connectionAddInId: "",
		isClientSideGetAttachmentSupported: isClientSideGetAttachmentSupported
	}

	let toTemp = getRecipientsFromMailboxItem(globalMailboxItem, RecipientSendType.TO) as Office.Recipients | Office.Recipients & Office.EmailAddressDetails[];
	let ccTemp = getRecipientsFromMailboxItem(globalMailboxItem, RecipientSendType.CC) as Office.Recipients | Office.Recipients & Office.EmailAddressDetails[];
	let bccTemp = getRecipientsFromMailboxItem(globalMailboxItem, RecipientSendType.BCC) as Office.Recipients | Office.Recipients & Office.EmailAddressDetails[];
	let bcc: EmailRecipient[] = [];
	if (bccTemp !== null) {
		bcc = await getRecipientsAsync(bccTemp, RecipientSendType.BCC);
	}

	if (isClientSideGetAttachmentSupported) {
		try {
			emailInfo.attachments = await getAttachmentsAsync(globalMailboxItem);
		} catch (e) {
			logger.error(e);
			emailInfo.attachments = [] as OutlookAttachment[];
		}
	}

	emailInfo.bodyType = await getBodyTypeAsync(globalMailboxItem);
	if (isSetSupported("1.7")) {
		let from = await getFromAsync(globalMailboxItem);
		emailInfo.from = from.emailAddress;
	}

	let [to, cc, subject, body] = await Promise.all([
		getRecipientsAsync(toTemp, RecipientSendType.TO),
		getRecipientsAsync(ccTemp, RecipientSendType.CC),		
		getSubjectAsync(globalMailboxItem),
		getBodyAsync(globalMailboxItem, emailInfo.bodyType)
	]);

	emailInfo.recipients = [...to, ...cc, ...bcc];
	emailInfo.subject = subject;
	emailInfo.body = body;
	let embeddedAttachments = getEmbeddedAttachments(emailInfo.body);
	emailInfo.attachments = emailInfo.attachments?.concat(embeddedAttachments)!;

	return emailInfo;
}

async function getAuthenticationToken(sendEvent, outlookToken: string): Promise<LoginModel | null>{
	try {
		let authToken: string = storageUtils.localGet(StorageDatatype.SafeSendToken) as string;
		let responseLogin = await restClient.login(authToken, outlookToken);
		return responseLogin.content;
	} catch (error) {
		logger.error(error);
		if (error instanceof UnauthorizedError) {
			let unauthorizedError = error as UnauthorizedError<LoginModel>			
			switch (unauthorizedError.response?.data.licenseKeyStatus) {
				case LicenseKeyStatus.Invalid:
					notificationLogger.errorReplace(globalMailboxItem, "i1", "Invalid license");
					break;
				case LicenseKeyStatus.TrialExpired:
					notificationLogger.errorReplace(globalMailboxItem, "i1", "Expired trial license");
					break;
				case LicenseKeyStatus.LicenseExpired:
					notificationLogger.errorReplace(globalMailboxItem, "i1", "Expired license");
					break;
			}

			setTimeout(() => { processSendEmail(sendEvent); }, 2000);
		} else {
			throw (error);
		}
	}

	return null;
}

function processSendEmail(sendEvent: any, send: boolean = true) {
	notificationLogger.remove(globalMailboxItem, "i1");
	sendEvent.completed({ allowEvent: send });
}

function isSetSupported(set: string): boolean {
	let isSetSupported = Office.context.requirements.isSetSupported("Mailbox", set);
	logger.info(`Set ${set} ${isSetSupported ? "is supported" : "is NOT supported"}`);

	return isSetSupported;
}

function getRecipientsFromMailboxItem(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	recipientType: RecipientSendType
): Office.Recipients | Office.Recipients & Office.EmailAddressDetails[] | null | undefined {
	switch (recipientType) {
		case RecipientSendType.TO:
			return mailboxItem?.itemType === Office.MailboxEnums.ItemType.Message ? mailboxItem.to : mailboxItem?.requiredAttendees;
		case RecipientSendType.CC:
			return mailboxItem?.itemType === Office.MailboxEnums.ItemType.Message ? mailboxItem.cc : mailboxItem?.optionalAttendees;
		case RecipientSendType.BCC:
			return mailboxItem?.itemType === Office.MailboxEnums.ItemType.Message ? mailboxItem.bcc : null;
	}

	return null;
}

async function removeRecipients(removedRecipientsList: RemovedRecipientsListType[]){
	const recipientMap = new Map<RecipientSendType, Office.Recipients | (Office.Recipients & Office.EmailAddressDetails[])>();
	for (const recipientType of Object.values(RecipientSendType))
	{
		const recipients = getRecipientsFromMailboxItem(globalMailboxItem, RecipientSendType[recipientType]);
		if (recipients)
		{
			recipientMap.set(RecipientSendType[recipientType], recipients);
		}
	}

	let logged = false;
	await Promise.all(removedRecipientsList.map(async element => {	
		if (element.recipients.length>0)
		{
			if(!logged){
				logger.info(`Removing recipients.`);
				logged = true;
			}	
			try{
				await removeAddressesFromRecipients(recipientMap.get(element.recipientType), element.recipients, element.recipientType);
			}
			catch(error) {
				notificationLogger.errorReplace(globalMailboxItem, "e1", "Failed to remove recipients");
				throw error;
			}
		}
	}));
}

async function removeAddressesFromRecipients(
	recipientsToBeUpdated:
		| Office.Recipients
		| (Office.Recipients & Office.EmailAddressDetails[]),
	addressesToRemove: string[],
	recipientType: RecipientSendType
) {
	if(!recipientsToBeUpdated){
		return;
	}
	
	const listOfRecipients = await getRecipientsAsync(
		recipientsToBeUpdated,
		recipientType,
	);
	const recipientsFiltered = listOfRecipients
		.filter((recipient) => !addressesToRemove.includes(recipient.emailAddress?.toLocaleLowerCase()))
		.filter((recipient) => !(recipient.isExchangeDistributionList && addressesToRemove.includes(recipient.name)))
		.map<Office.EmailAddressDetails>((recipient) => {
			return {
				emailAddress: recipient.emailAddress.toLowerCase(),
				displayName: recipient.name,
			} as Office.EmailAddressDetails;
		});
	

	return new Promise((resolve, reject) =>{
		recipientsToBeUpdated?.setAsync(recipientsFiltered, (asyncResult) => {
			try {
				if (asyncResult.status === Office.AsyncResultStatus.Failed) {
					reject(asyncResult.error);
				}
	
				resolve(asyncResult.value);
			} catch (error) {
				logger.error(error);
				reject(error);
			}
		});
	});
}

async function removeAttachments(removedAttachmentsList: Array<string>){
	if(removedAttachmentsList.length === 0){
		return;
	}
	logger.info("Removing attachments.");
	await Promise.all(removedAttachmentsList.map(async element => {
			try {
				await removeAttachmentFromMailItem(element);
			}
			catch (error) {
				notificationLogger.errorReplace(globalMailboxItem, "e1", "Failed to remove attachments");
				throw error;
			}
	}));
}

async function removeAttachmentFromMailItem(attachmentID: string) {	
	return new Promise((resolve, reject)=>{
		const ewsID = Office.context.mailbox.convertToEwsId(attachmentID, Office.MailboxEnums.RestVersion.v2_0);

		Office.context.mailbox.item?.removeAttachmentAsync(ewsID, {asyncContext: null}, (asyncResult)=>{
			try {
				if (asyncResult.status === Office.AsyncResultStatus.Failed) {
					reject( asyncResult.error);
				}
	
				resolve(asyncResult.value);
			} catch (error) {
				logger.error(error);
				reject( error);
			}
		});
	});
}

function getRecipientsAsync(
	recipients: Office.Recipients | Office.Recipients & Office.EmailAddressDetails[],
	recipientType: RecipientSendType
): Promise<EmailRecipient[]> {
	return new Promise((resolve, reject) => {
		recipients.getAsync((asyncResult) => {
			try {
				if (asyncResult.status === Office.AsyncResultStatus.Failed) {
					reject(asyncResult.error);
				}

				let recipientsTemp: EmailRecipient[] = asyncResult.value.map((recipient) => (
					{
						id: 0,
						emailAddress: recipient.emailAddress,
						name: recipient.displayName,
						isAddressTypeSupported: false,
						isExchangeDistributionList: recipient.recipientType === Office.MailboxEnums.RecipientType.DistributionList,
						isOutlookDistributionList: false,
						members: [],
						sendable: true,
						sendType: recipientType,
						recipientType: recipient.recipientType
					}
				));

				resolve(recipientsTemp);
			} catch (e) {
				reject(e);
			}
		});
	});
}

function getFromAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
): Promise<Office.EmailAddressDetails> {
	return new Promise((resolve, reject) => {
		if (mailboxItem?.itemType === Office.MailboxEnums.ItemType.Message) {
			mailboxItem?.from.getAsync({ asyncContext: null }, callback);
		} else {
			mailboxItem?.organizer.getAsync({ asyncContext: null }, callback);
		}
		function callback(asyncResult) {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}
			resolve(asyncResult.value);
		}
	});
}

function getSubjectAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
): Promise<string> {
	return new Promise((resolve, reject) => {
		mailboxItem?.subject.getAsync((asyncResult) => {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}

			resolve(asyncResult.value);
		});
	});
}

function getBodyTypeAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
): Promise<Office.CoercionType> {
	return new Promise((resolve, reject) => {
		mailboxItem?.body.getTypeAsync({ asyncContext: null }, function (asyncResult) {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}

			resolve(asyncResult.value);
		});
	});
}

function getEmbeddedAttachments(
	bodyContent: string
): OutlookAttachment[] {
	let bodyasElement = document.createElement("html");
	bodyasElement.innerHTML = bodyContent;
	let embImgs = bodyasElement.getElementsByTagName("img");
	let attachmentsTemp: OutlookAttachment[] = [] as OutlookAttachment[];
	if (embImgs !== null) {
		for (var i = 0; i < embImgs.length; i++) {
			let itemId = embImgs[i].attributes.getNamedItem("data-custom")?.value;
			if (itemId) {
				let extension = embImgs[i].src.split(',')[0].replace("data:image/", "").split(";")[0];
				let prefix = i < 10 ? "000" : i < 100 ? "00" : i < 1000 ? "0" : "";
				let name = prefix + i + "." + extension;
				let content = embImgs[i].src.split(',')[1];
				let sizestr = embImgs[i].attributes.getNamedItem("size")?.value!;
				let size = parseInt(sizestr) || 0;

				attachmentsTemp.push({
					id: Office.context.mailbox.convertToRestId(itemId, Office.MailboxEnums.RestVersion.v2_0),
					jsId: itemId,
					name: name,
					attachmentType: "Embedded",
					contentBytes: content,
					contentType: "",
					isInline: true,
					size: size
				})
			}
		}
	}

	return attachmentsTemp;
}

function getBodyAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	coercionType: Office.CoercionType
): Promise<string> {
	return new Promise((resolve, reject) => {
		mailboxItem?.body.getAsync(coercionType, { asyncContext: null }, (asyncResult) => {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}

			resolve(asyncResult.value);
		});
	});
}

function getAttachmentsAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
): Promise<OutlookAttachment[]> {
	return new Promise((resolve, reject) => {
		mailboxItem?.getAttachmentsAsync({}, (asyncResult) => {
			try {
				if (asyncResult.status === Office.AsyncResultStatus.Failed) {
					reject(asyncResult.error);
				}

				let attachments: OutlookAttachment[] = asyncResult.value.map((attachment) => (
					{
						id: Office.context.mailbox.convertToRestId(attachment.id, Office.MailboxEnums.RestVersion.v2_0),
						jsId: attachment.id,
						name: !attachment.name.includes(".") && attachment.attachmentType === Office.MailboxEnums.AttachmentType.Item ? `${attachment.name}.eml` : attachment.name,
						isInline: attachment.isInline ?? false,
						size: attachment.size ?? 0,
						attachmentType: attachment.attachmentType,
						contentBytes: null,
						contentType: ""
					}
				))

				resolve(attachments);
			} catch (e) {
				reject(e);
			}
		});
	});
}

function isBase64(
	attachmentContent: string,
): boolean {
	let isBase64 = true;
	let paddingFound = false;
	for (let c of attachmentContent) {
		if (c === '=') {
			paddingFound = true;
		}

		if (!((!paddingFound && (c === '+' || ('/' <= c && c <= '9') || ('a' <= c && c <= 'z') || ('A' <= c && c <= 'Z'))) || (c === '='))) {
			isBase64 = false;
			logger.info("Found non-b64 character " + c);
			break;
		}
	}

	return isBase64;
}

function getAttachmentContentAsync(
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined,
	attachment: OutlookAttachment
): Promise<string> {
	return new Promise((resolve, reject) => {
		mailboxItem?.getAttachmentContentAsync(attachment.jsId, {}, (asyncResult) => {
			if (asyncResult.status === Office.AsyncResultStatus.Failed) {
				reject(asyncResult.error);
			}

			resolve(asyncResult.value.content);				
		})
	});
}

function saveItemAndGetOutlookTokenAsync(
	isOffice365: boolean,
	mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined
): Promise<IEmailSettings> {
	return new Promise((resolve, reject) => {
		mailboxItem?.saveAsync(async (asyncResult) => {
			try {
				if (asyncResult.status === Office.AsyncResultStatus.Failed) {
					reject(asyncResult.error);
				}

				let id = asyncResult.value;
				let itemId = Office.context.mailbox.convertToRestId(id, Office.MailboxEnums.RestVersion.v2_0);
				let emailSettings: IEmailSettings = {
					itemId: itemId,
					outlookToken: ""
				}

				if (isOffice365) {
					resolve(emailSettings);
				}

				Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, (asyncResult) => {
					if (asyncResult.status === Office.AsyncResultStatus.Failed) {
						reject(asyncResult.error);
					}

					emailSettings.outlookToken = asyncResult.value;
					resolve(emailSettings);
				});
			} catch (e) {
				reject(e);
			}
		});
	});
}

function getOutlookTokenAsync(
): Promise<string> {
	return new Promise(async (resolve, reject) => {
		try {
			//throw new MockSSOError("13012");
			// Set forMSGraphAccess: false because of this issue https://github.com/OfficeDev/office-js/issues/2711
			let options: OfficeRuntime.AuthOptions = { allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: false };
			let ssoOutlookToken = await OfficeRuntime.auth.getAccessToken(options);
			resolve(ssoOutlookToken);
		} catch (e) {
			reject(e);
		}
	});
}

//For SSO testing purposes only
//function MockSSOError(code) {
//	this.name = "API Not Supported";
//	this.message = "API is not supported in this platform.";
//	this.code = code;
//}
