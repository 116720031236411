import * as React from 'react';
import { MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import { StorageDatatype, storageUtils, systemInfo, WebBrowser } from '../lib/Utils';
import FileSaver from 'file-saver';
import LandingPage from './LandingPage';
import { browserName } from 'react-device-detect';

interface LandingPageDesktopState {
	isWebKit: boolean,
	logCopied: boolean
}

export default class LandingPageDesktop extends React.Component<{}, LandingPageDesktopState> {
	constructor(props) {
		super(props);

		this.state = {
			isWebKit: browserName === WebBrowser.WebKit,
			logCopied: false
		}

		this.downloadLogFile = this.downloadLogFile.bind(this);
	}

	async downloadLogFile(): Promise<void> {
		if (!storageUtils.localExists(StorageDatatype.Log)) {
			storageUtils.localSet(StorageDatatype.Log, "");
		}

		let log = storageUtils.localGet(StorageDatatype.Log);
		log = `${log}--------------------------------------------------\n${await systemInfo()}\n`;

		if (this.state.isWebKit) {
			await navigator.clipboard.writeText(log);

			this.setState({
				logCopied: true
			});

			setTimeout(() => {
				this.setState({
					logCopied: false
				});
			}, 4000);
		} else {
			var blob = new Blob([log], { type: "text/plain;charset=utf-8" });
			FileSaver.saveAs(blob, "log.txt");
		}

		storageUtils.localRemove(StorageDatatype.Log);
	}

	render() {
		return (
			<>
				<LandingPage></LandingPage>
				<Stack className="ms-welcome__main">
					<PrimaryButton onClick={() => this.downloadLogFile()} text={this.state.isWebKit ? "Copy Log to clipboard" : "Download Log file"} />
					{
						this.state.logCopied &&
						<MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
							Log copied successfully
						</MessageBar>
					}
				</Stack>
			</>
        );
    }
}
