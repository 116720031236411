/* global Office */  //Required for this to be found.  see: https://github.com/OfficeDev/office-js-docs-pr/issues/691

import * as React from 'react';
import OfficeDialog from '../OfficeDialog';
import { ApiResponseView, ActionOnMatch, DlpItemResultView, ContentScanningResultView, ClientKeywordDomainResultView, SignalRUIHubMethods } from '../Backend/BackendTypes'
import { AppProps, AppState, AppStateUI, GroupByAttachments, IChunksManager } from './AppTypes';
import { Modules } from '../../LogConfiguration';
import { safeSendLogging } from '../../lib/Logger';
import { SignalRHubType, StorageDatatype, storageUtils } from '../../lib/Utils';
import { appUtils } from './AppUtils';
import ForbiddenRecipientDialog from '../ForbiddenRecipientDialog';
import OutlookSpinner from '../OutlookSpinner';
import { getSignalRConnection } from '../../lib/AddIn';

const logger = safeSendLogging.getLogger(Modules.SafeSend_App);

export default class App extends React.Component<AppProps, AppState> {
    sendEvent: any;
    mailboxItem: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead = {} as Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead;
    officeDialog: OfficeDialog;
    chunksManager: IChunksManager = { chunkSize: 0, chunkProcessedCount: 0, dlpItemResults: [] };
    constructor(props, context) {
        super(props, context);
        this.state = {
			dialogId: "",
			isOfficeInitialized: false,
            apiResponseView: ({} as any) as ApiResponseView,
            dlps: [],
            contentScanningResults: [],
            clientKeywordDomainResults: [],
            contentScanningAction: ActionOnMatch.Inform,
            countUnprotectedAttachments: 0,
            unprotectedAttachmentMessage: "",
            isDlpWaiting: false,
            scanTimedOut: false,
            dialogInstance: {} as Office.Dialog,
            groupByAttachments: {
                countTotalAttachmentsScanned: 0,
                countTotalClientKeyword: 0,
                countTotalClientKeywordMatches: 0,
                countTotalContentScanning: 0,
                countTotalContentScanningMatches: 0,
                groupByClientKeywordAttachments: false,
                groupByClientKeywordAttachmentsText: "",
                groupByContentScanningAttachments: false,
                groupByContentScanningAttachmentsText: ""
            },
            confirmationRequired: false
        };
        this.officeDialog = {} as OfficeDialog;
        this.configureSignalR = this.configureSignalR.bind(this);
        this.updateDialogUI = this.updateDialogUI.bind(this);
    }

    updateDialogUI(dlpItemResults: DlpItemResultView[], isDlpWaiting: boolean, scanTimedOut: boolean, confirmationRequired: boolean) {
        logger.debug('Update dialog UI');
        let groupByAttachments: GroupByAttachments = appUtils.getGroupByAttachments(this.state.apiResponseView.onSendProcessContex.settings, this.state.groupByAttachments, dlpItemResults);
        var newAppState: AppStateUI = appUtils.getStateForUpdateUI(
            this.state.apiResponseView.onSendProcessContex.settings,
            this.state.clientKeywordDomainResults,
            this.state.contentScanningResults,
            this.state.contentScanningAction,
            groupByAttachments.groupByClientKeywordAttachments,
            groupByAttachments.groupByContentScanningAttachments,
            dlpItemResults,
            isDlpWaiting,
            scanTimedOut,
            this.state.countUnprotectedAttachments
        );

       if (!this.state.confirmationRequired && confirmationRequired) {
          this.setState({ confirmationRequired: confirmationRequired });
		 }

        this.setState({
            isDlpWaiting: newAppState.isDlpWaiting,
            scanTimedOut: newAppState.scanTimedOut,
            clientKeywordDomainResults: newAppState.clientKeywordDomainResults,
            contentScanningResults: newAppState.contentScanningResults,
            contentScanningAction: newAppState.contentScanningAction,
            dlps: newAppState.dlps,
            groupByAttachments: groupByAttachments,
            countUnprotectedAttachments: newAppState.countUnprotectedAttachments,
            unprotectedAttachmentMessage: newAppState.unprotectedAttachmentMessage
        });

        this.chunksManager.dlpItemResults = [];
        this.chunksManager.chunkProcessedCount = 0;
        this.officeDialog.setSendEnabled();
    }

    async configureSignalR(authToken: string) {
		try {
			let connection = await getSignalRConnection(authToken, SignalRHubType.UIHub);
			connection.onclose(async error => {
				logger.error(`${SignalRHubType.UIHub} error:`, error);
			});

			connection.on(SignalRUIHubMethods.ReceiveDlpItemResults, (dlpItemResult: DlpItemResultView, confirmationRequired: boolean) => {
				logger.debug(SignalRUIHubMethods.ReceiveDlpItemResults);
				this.chunksManager.dlpItemResults.push(dlpItemResult);
				this.chunksManager.chunkProcessedCount++;

				if (this.state.confirmationRequired !== confirmationRequired) {
					this.setState({
					confirmationRequired: confirmationRequired
					});

					if (this.chunksManager.chunkProcessedCount === this.chunksManager.chunkSize) {
					this.updateDialogUI(this.chunksManager.dlpItemResults, true, false, confirmationRequired);
					}
				}
			});

			connection.on(SignalRUIHubMethods.ReceiveFinishingContentScanning, (_isCancellationRequested: boolean, confirmationRequired: boolean, scanTimedOut: boolean, auditStringDlpResults: string) => {
				logger.debug(SignalRUIHubMethods.ReceiveFinishingContentScanning);
				storageUtils.localSet(StorageDatatype.AuditStringDlpResults, auditStringDlpResults);

				if (!this.state.confirmationRequired) {
					this.officeDialog.closeDialog({ send: true, encrypt: false, showEncryptPrompt: false });
			   } else {
					this.updateDialogUI(this.chunksManager.dlpItemResults, false, scanTimedOut, confirmationRequired);
			   }
			});

			connection.on(SignalRUIHubMethods.ReceiveAttachmentPasswordRequest, () => {
				logger.debug(SignalRUIHubMethods.ReceiveAttachmentPasswordRequest);
				this.officeDialog.attachmentPasswordRequest();
			});
		} catch (error) {
			logger.error(`${SignalRHubType.UIHub} error:`, error);
		}
    }

	componentDidMount() {
		Office.onReady(async (info) => {
			if (info.host !== null) {
				logger.debug("Load data from local storage");
				let dialogId = storageUtils.localGet(StorageDatatype.DialogId) as string;
				let localStorageData = storageUtils.localGet(StorageDatatype.ApiResponse);
				let apiResponseView = JSON.parse(localStorageData) as ApiResponseView
				storageUtils.localRemove(StorageDatatype.ApiResponse);

				let isDlpWaiting = apiResponseView.onSendProcessContex.isDlpLicenseValid &&
					apiResponseView.onSendProcessContex.scanStarted &&
					!apiResponseView.onSendProcessContex.scanCompleted;

				await this.setState({
					dialogId: dialogId,
					isOfficeInitialized: true,
					isDlpWaiting: isDlpWaiting,
					apiResponseView: apiResponseView,
					confirmationRequired: apiResponseView.onSendProcessContex.confirmationRequired
				});

				if (apiResponseView.onSendProcessContex?.forbiddenRecipients?.length === 0) {
					this.updateDialogUI(apiResponseView.dlpScanResults, isDlpWaiting, apiResponseView.onSendProcessContex.scanTimedOut, apiResponseView.onSendProcessContex.confirmationRequired);
					if (isDlpWaiting) {
						let estimatedDlpItemsCount = apiResponseView.unsafeAttachmentsCount - apiResponseView.dlpScanResults.length + 2;
						this.chunksManager.chunkSize = Math.round(estimatedDlpItemsCount / 2);
						let authToken: string = storageUtils.localGet(StorageDatatype.SafeSendToken) as string;
						await this.configureSignalR(authToken);
					}
				}
			}
		});
    }

   render() {
       if (!this.state.isOfficeInitialized) {
		   return (
			   <OutlookSpinner message="" />
           );
       } else if (this.state.apiResponseView.onSendProcessContex?.forbiddenRecipients?.length > 0) {
           return (<ForbiddenRecipientDialog dialogId={this.state.dialogId} apiResponseView={this.state.apiResponseView} />);
       }

       return (
           <OfficeDialog onRef={ref => (this.officeDialog = ref)}
               dialogId={this.state.dialogId}
               apiResponseView={this.state.apiResponseView}
               dlps={this.state.dlps}
               isDlpWaiting={this.state.isDlpWaiting}
               confirmationRequired={this.state.confirmationRequired}
               scanTimedOut={this.state.scanTimedOut}
               contentScanningAction={this.state.contentScanningAction}
               contentScanningResults={this.state.contentScanningResults.length > 0 ? this.state.contentScanningResults.sort((a: ContentScanningResultView, b: ContentScanningResultView) => ((a.attachmentPath??'').localeCompare(b.attachmentPath??''))) : this.state.contentScanningResults}
               clientKeywordDomainResults={this.state.clientKeywordDomainResults.length > 0 ? this.state.clientKeywordDomainResults.sort((a: ClientKeywordDomainResultView, b: ClientKeywordDomainResultView) => ((a.attachmentPath??'').localeCompare(b.attachmentPath??''))) : this.state.clientKeywordDomainResults}
               groupByAttachments={this.state.groupByAttachments}
               countUnprotectedAttachments={this.state.countUnprotectedAttachments}
               unprotectedAttachmentMessage={this.state.unprotectedAttachmentMessage}
            />
        );
   }
}