/*global Office */  //Required for this to be found.  see: https://github.com/OfficeDev/office-js-docs-pr/issues/691

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fluentui/react/dist/css/fabric.min.css';
import { Image } from '@fluentui/react/lib/Image';
import * as React from 'react';
import { Checkbox, ChoiceGroup, DefaultButton, FontIcon, IChoiceGroupOption, IMessageBarStyles, IPanelStyles, IScrollablePaneStyles, ITextFieldStyles, Label, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, ProgressIndicator, ScrollablePane, Separator, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import { Stack, IStackTokens, IStackStyles } from '@fluentui/react/lib/Stack';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ApiResponseView, ActionOnMatch, ContentScanningResultView, ClientKeywordDomainResultView, TriggerEncryptionMode, UnprotectedAttachmentsMode, RecipientSendType } from './Backend/BackendTypes'
import ClientKeywordDomainResultsList from './Dlp/ClientKeywordDomainResultsList';
import ContentScanningResultsList from './Dlp/ContentScanningResultsList';
import ConfirmableList from './Confirmable/ConfirmableList';
import { IDlp } from './Confirmable/ConfirmableTypes';
import { Modules } from '../LogConfiguration';
import { safeSendLogging } from '../lib/Logger';
import { GroupByAttachments } from './App/AppTypes';
import { browserName } from 'react-device-detect';
import { WebBrowser, InjectLinkToString, fixupHtml, storageUtils, StorageDatatype } from '../lib/Utils';
import { restClient } from '../RestClient';
import LostConnectionPage from './LostConnectionPage';

const logger = safeSendLogging.getLogger(Modules.SafeSend_OfficeDialog);
const textFieldStyles: Partial<ITextFieldStyles> = { root: { position: 'relative', paddingLeft: '5px' } };

const stackTextFieldStyles: Partial<IStackStyles> = {
	root: {
		paddingTop: '15px'
	}
};

const confirmationStackTokens: IStackTokens = {
	childrenGap: 5,
};

const stringMainPolicySpaces: IStackTokens = {
	childrenGap: 5,
	padding: 5,
};

const messageBarStylesRigth: IMessageBarStyles = {
	content: {
		flexDirection: "row-reverse",
		textAlign: "right",
	},
	innerText: {
		width: "100%"
	}
}

const stackTokens: IStackTokens = {
	padding: 20,
};

const iconNotificationClass = mergeStyles({
	padding: '0px 0px 0px 5px',
	fontSize: '20px',
	verticalAlign: 'middle',
	maxHeight: '20px',
	maxWidth: '20px',
});

const classNames = mergeStyleSets({
	wrapper: {
		height: '100%',
		position: 'relative',
		maxHeight: 'inherit',
	},
	pane: {
		maxWidth: '100%',
		border: '1px solid #ccc',
	},
	sticky: {
		border: '1px solid #ccc',
		padding: '1px 0px 1px 0px',
		fontSize: '13px',
	},
	textContent: {
		padding: '15px 10px',
	},
	content: {
		height: '100%',
	},
	progressIndicator: {
		width: "100%",
	},
	notificationArea: {
		display: 'flex',
	},
	error: [{ color: 'red', cursor: 'default' }, iconNotificationClass],
	warning: [{ color: '#ffc107', cursor: 'default' }, iconNotificationClass],
	info: [{ color: '#6c757d', cursor: 'default' }, iconNotificationClass],
	completed: [{ color: 'green', cursor: 'default' }, iconNotificationClass],
});

const notificationAreaStackTokens: IStackTokens = {
	childrenGap: 5,
	padding: '5px 0px 0px 0px',
};

const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
	root: classNames.pane,
};

const panelStyles: Partial<IPanelStyles> = {
	content: { height: "100%", padding: "0px 20px 0px 20px", margin: "0px" },
	header: { padding: "0px 0px 10px 19px", margin: "0px" }
}

interface ISettings {
	confirmableAllSelected: boolean,
	bccWarningIfMoreThanX: boolean,
	showConfirmContentScanningText: boolean,
	confirmContentScanningText: string,
	showConfirmContentScanningCheck: boolean,
	confirmContentScanningCheck: boolean,
	showConfirmClientKeywordsText: boolean,
	confirmClientKeywordsText: string,
	denySend: boolean,
	encryptYesSelected: boolean,
	encryptNoSelected: boolean,
	showEncryptPrompt: boolean,
	confirmUnprotectedAttachmentsCheck: boolean,
	attachmentPassword: string
}

export interface DialogValues {
	send: boolean;
	encrypt: boolean;
	showEncryptPrompt: boolean;
	removedRecipientsList?: Array<RemovedRecipientsListType>;
	removedAttachmentsList?: Array<RemovedAttachmentsListType>;
}

export interface DialogProps {
	dialogId: string,
	apiResponseView: ApiResponseView,
	dlps: IDlp[],
	confirmationRequired: boolean;
	isDlpWaiting: boolean;
	scanTimedOut: boolean;
	countUnprotectedAttachments: number;
	unprotectedAttachmentMessage: string;
	contentScanningAction: ActionOnMatch;
	onRef: any;
	contentScanningResults: ContentScanningResultView[];
	clientKeywordDomainResults: ClientKeywordDomainResultView[];
	groupByAttachments: GroupByAttachments;
}

export type RemovedRecipientsListType = {
	recipientType: RecipientSendType,
	recipients: Array<string>,
}

export type RemovedAttachmentsListType = {
	id: string,
	name: string,
	size: number,
}
export interface DialogState {
	isConnectionLost: boolean;
	enabledSend: boolean;
	defaultValuesSettingUp: boolean;
	showAttachmentPasswordPrompt: boolean;
	hasShownPassowrdPrompt: boolean;
	enabledSubmitPassword: boolean;
	removedRecipientsList: Array<RemovedRecipientsListType>
	removedAttachmentsList: Array<RemovedAttachmentsListType>
}

export default class OfficeDialog extends React.Component<DialogProps, DialogState>{
	settings: ISettings;
	mailboxItem: any;
	sendEvent: any;
	constructor(props, context) {
		super(props, context);
		this.state = {
			isConnectionLost: false,
			enabledSend: false,
			defaultValuesSettingUp: false,
			showAttachmentPasswordPrompt: false,
			hasShownPassowrdPrompt: false,
			enabledSubmitPassword: false,
			removedRecipientsList: [
				{
					recipientType: RecipientSendType.TO,
					recipients: []
				},
				{
					recipientType: RecipientSendType.CC,
					recipients: []
				},
				{
					recipientType: RecipientSendType.BCC,
					recipients: []
				},
			],
			removedAttachmentsList: []
		}

		this.settings = {
			confirmableAllSelected: false,
			bccWarningIfMoreThanX: false,
			showConfirmContentScanningText: false,
			confirmContentScanningText: '',
			showConfirmContentScanningCheck: false,
			confirmContentScanningCheck: false,
			showConfirmClientKeywordsText: false,
			confirmClientKeywordsText: '',
			denySend: false,
			encryptNoSelected: false,
			encryptYesSelected: false,
			showEncryptPrompt: false,
			attachmentPassword: "",
			confirmUnprotectedAttachmentsCheck: false
		}

		this.closeDialog = this.closeDialog.bind(this);
		this.attachmentPasswordRequest = this.attachmentPasswordRequest.bind(this);
		this.attachmentSendPassword = this.attachmentSendPassword.bind(this);
		this.setSendEnabled = this.setSendEnabled.bind(this);
		this.isBccWarningVisible = this.isBccWarningVisible.bind(this);
		this.isEncryptVisible = this.isEncryptVisible.bind(this);
		this.setEncryptVisibility = this.setEncryptVisibility.bind(this);
		this.isProcessMaxNrRecipients = this.isProcessMaxNrRecipients.bind(this);
		this.validateEncryptionSelection = this.validateEncryptionSelection.bind(this);
		this.handleRecipientRemoval = this.handleRecipientRemoval.bind(this);
		this.handleAttachmentRemoval = this.handleAttachmentRemoval.bind(this);
		this.handleGroupRemoval = this.handleGroupRemoval.bind(this);
		this.isRecipientRemovalDisabled = this.isRecipientRemovalDisabled.bind(this);
		this.isRecipientAttachmentRemovalEnabled = this.isRecipientAttachmentRemovalEnabled.bind(this);
	}
	
	componentDidMount() {
		this.setDialogTimeout(240);
		this.props.onRef(this);

		window.addEventListener("storage", () => {
			let dialogId = storageUtils.localGet(StorageDatatype.DialogId) as string;
			if (this.props.dialogId !== dialogId) {
				logger.warn("Dialog connection lost");
				this.setState({
					isConnectionLost: true
				});
			}
		});
	}

	componentWillUnmount() {
		this.props.onRef(undefined)
	}

	closeDialog(dialogOptions: DialogValues) {
		Office.context.ui.messageParent(JSON.stringify(dialogOptions));
	}

	isBccWarningVisible() {
		return this.props.apiResponseView.onSendProcessContex.confirmationFlags.showBccWarning;
	}

	isEncryptVisible() {
		return this.settings.showEncryptPrompt;
	}

	setEncryptVisibility(encrypt: boolean) {
		this.settings.showEncryptPrompt = encrypt;
	}

	validateEncryptionSelection(option: string | undefined, isDefault: boolean): string | undefined {
		if (!this.state.defaultValuesSettingUp && isDefault) {
			this.setState({
				defaultValuesSettingUp: true,
			});
		}

		if (this.state.defaultValuesSettingUp && isDefault) {
			return option;
		}

		if (option === 'Yes') {
			this.settings.encryptYesSelected = true;
			this.settings.encryptNoSelected = false;
		} else if (option === 'No') {
			this.settings.encryptNoSelected = true;
			this.settings.encryptYesSelected = false;
		}
		this.setSendEnabled();
		return option;
	}

	isUnprotectedAttachmentsNotificationVisible() {
		return this.props.countUnprotectedAttachments > 0 &&
			(this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Notify
				|| this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Deny);
	}

	isUnprotectedAttachmentsComfirmationVisible() {
		return this.props.countUnprotectedAttachments > 0 &&
			this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Confirm;
	}

	isPasswordPromptVisible() {
		return this.props.isDlpWaiting && ((this.props.apiResponseView.attachmentPasswordRequested && !this.state.hasShownPassowrdPrompt) || this.state.showAttachmentPasswordPrompt);
	}

	isRecipientRemovalDisabled() {
		return this.props.apiResponseView.hasDistributionLists || this.isProcessMaxNrRecipients();
	}

	isRecipientAttachmentRemovalEnabled() {
		return this.props.apiResponseView.onSendProcessContex.settings._enableRecipientAttachmentRemoval;
	}

	isProcessMaxNrRecipients(): boolean {
		let recipients = this.props.apiResponseView.unsafeRecipients.to.concat(this.props.apiResponseView.unsafeRecipients.cc).concat(this.props.apiResponseView.unsafeRecipients.bcc);
		let result = false;
		recipients.forEach((recipient) => {
			if (recipient.tooManyRecipientsCount > 0) {
				result = true;
			}
		});
		return result;
	}

	setDialogTimeout(timeoutSeconds: number) {
		var timeoutMs = timeoutSeconds * 1000;
		setTimeout(dialogTimeout, timeoutMs);
		function dialogTimeout() {
			logger.info("Dialog timeout reached, closing dialog.");
			let dialogValues = JSON.stringify({ send: false, encrypt: false });
			Office.context.ui.messageParent(dialogValues);
		}
	}

	attachmentPasswordRequest() {
		this.setState({
			showAttachmentPasswordPrompt: true
		});
	}

	async attachmentSendPassword(password: string) {
		this.setState({
			hasShownPassowrdPrompt: true,
			showAttachmentPasswordPrompt: false,
			enabledSubmitPassword: false
		});

		let authToken = storageUtils.localGet(StorageDatatype.SafeSendToken) as string;
		await restClient.sendAttachmentPassword(authToken, password);
	}

	setSendEnabled() {
		this.settings.denySend = this.props.contentScanningAction === ActionOnMatch.Deny
			|| (this.props.countUnprotectedAttachments !== 0 && this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Deny);
		this.settings.showConfirmContentScanningCheck = this.props.contentScanningAction === ActionOnMatch.Confirm;
		this.settings.showConfirmContentScanningText = this.props.contentScanningAction === ActionOnMatch.ConfirmText;
		this.settings.showConfirmClientKeywordsText = this.props.clientKeywordDomainResults.length > 0 && !this.props.apiResponseView.onSendProcessContex.settings._clientKeywordDisableConfirm;
		let enabled = this.settings.confirmableAllSelected && !this.props.isDlpWaiting && (this.isBccWarningVisible() ? this.settings.bccWarningIfMoreThanX : true)
			&& (this.props.contentScanningAction === ActionOnMatch.Inform
				|| (this.props.contentScanningAction === ActionOnMatch.Confirm && this.settings.confirmContentScanningCheck)
				|| (this.props.contentScanningAction === ActionOnMatch.ConfirmText && this.settings.confirmContentScanningText.toLowerCase() === "confirm"))
			&& (!this.settings.showConfirmClientKeywordsText || this.settings.confirmClientKeywordsText.toLowerCase() === "confirm")
			&& (!this.isEncryptVisible() || this.settings.encryptYesSelected || this.settings.encryptNoSelected)
			&& (this.props.countUnprotectedAttachments === 0
				|| this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Ignore
				|| this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Notify
				|| (this.props.apiResponseView.onSendProcessContex.settings._unprotectedAttachmentsMode === UnprotectedAttachmentsMode.Confirm
					&& this.settings.confirmUnprotectedAttachmentsCheck)
			)
			;
		this.setState({
			enabledSend: enabled
		});
	}

	handleGroupRemoval(recipientsList: string[], isRemoved: boolean){
		const updatedRemovedRecipientsList = this.state.removedRecipientsList.map(list => {
			if (isRemoved)
			{
				list.recipients = [...list.recipients, ...recipientsList];
			}
			else {
				list.recipients = list.recipients.filter(recipientName => !recipientsList.includes(recipientName));
			}

			return list;
		});

		this.setState({
			...this.state,
			removedRecipientsList: updatedRemovedRecipientsList, 
		});
	}

	handleRecipientRemoval(name: string, recipientType: keyof typeof RecipientSendType, isRemoved: boolean){
		const selectedList = this.state.removedRecipientsList.find(list => list.recipientType === recipientType);
		if(selectedList)
		{
			selectedList.recipients = isRemoved ?  [...selectedList.recipients, name.toLowerCase()] : selectedList.recipients.filter(recipientName => recipientName !== name.toLowerCase());
			const updatedList = this.state.removedRecipientsList.map(list => {
				if(list.recipientType === recipientType)
				{
					return selectedList;
				}
				
				return list;
			});

			this.setState({
				...this.state,
				removedRecipientsList: updatedList, 
			});
		}
	}

	handleAttachmentRemoval(attachmentID: string, isRemoved: boolean, size: number, name: string){
		let {removedAttachmentsList: attachmentsRemoved} = this.state;
		attachmentsRemoved = isRemoved ? [...attachmentsRemoved, {id: attachmentID, size: size, name: name}] : attachmentsRemoved.filter(attachment => attachment.id !== attachmentID);

		this.setState({
			...this.state,
			removedAttachmentsList: attachmentsRemoved,
		})
	}

	render() {
		if (this.props.apiResponseView.onSendProcessContex) {
			this.setEncryptVisibility(this.props.apiResponseView.onSendProcessContex.confirmationFlags.showEncryptPrompt);
		}
		const onRenderFooterContent = () => (
			<Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft} id="child" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
				<Image
					src={`data:image/png;base64,${this.props.apiResponseView.onSendProcessContex?.settings.logoBytes}`}
					alt="placeholder"
					height={48}
				/>

				<Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft} id="child" style={{ display: "flex", justifyContent: "flex-end" }}>
					<PrimaryButton 
						onClick={() => this.closeDialog({
							send: true, 
							encrypt: this.settings.encryptYesSelected, 
							showEncryptPrompt: this.isEncryptVisible(), 
							removedRecipientsList: this.state.removedRecipientsList,
							removedAttachmentsList: this.state.removedAttachmentsList}
						)}
						text={this.props.apiResponseView.onSendProcessContex?.settings._stringMainSendButton} 
						disabled={!this.state.enabledSend} 
					/>
					<Separator vertical={true} />
					<DefaultButton onClick={() => this.closeDialog({ send: false, encrypt: false, showEncryptPrompt: this.isEncryptVisible(), removedRecipientsList: this.state.removedRecipientsList })}
						text={this.props.apiResponseView.onSendProcessContex?.settings._stringMainCancelButton} />
				</Stack>
			</Stack>
		)

		const isConnectionLost = Object.entries(this.props.apiResponseView).length === 0;
		if (this.props.apiResponseView.onSendProcessContex?.settings._triggerEncryptionMode === TriggerEncryptionMode.AskWhenFoundSensitiveContent
			&& this.props.contentScanningResults.length > 0) {
			logger.debug("Asking to encrypt because sensitive content found");
			this.setEncryptVisibility(true);
		}
		return (
			<>
				{
					(isConnectionLost || this.state.isConnectionLost) &&
					<LostConnectionPage/>
				}
				{
					!isConnectionLost && !this.state.isConnectionLost &&
					<Panel
						isOpen={true}
						type={PanelType.smallFluid}
						onRenderFooterContent={onRenderFooterContent}
						hasCloseButton={false}
						headerText={this.props.apiResponseView.onSendProcessContex.confirmationFlags.mainTitle}
						isFooterAtBottom={true}
						className={classNames.content}
						styles={panelStyles}
					>
						<Stack className={classNames.content} >
							{
								this.isBccWarningVisible() &&
								<Stack style={this.props.apiResponseView.alignRightToLeft ? { alignItems: "flex-end", textAlign: "right" } : { alignItems: "flex-start" }}>
									<Checkbox
										boxSide={this.props.apiResponseView.alignRightToLeft ? "end" : "start"}
										disabled={this.settings.denySend}
										label={this.props.apiResponseView.onSendProcessContex?.settings._stringMainBCCWarning}
										checked={this.settings.bccWarningIfMoreThanX}
										onChange={(e) => { this.settings.bccWarningIfMoreThanX = (e?.target as HTMLInputElement).checked; this.setSendEnabled(); }}
									/>
								</Stack>
							}
							{
								this.props.apiResponseView.onSendProcessContex?.settings._showFromAddress &&
								<Label>
									{this.props.apiResponseView.fromAddress}
								</Label>
							}
							{
								this.props.apiResponseView.onSendProcessContex?.confirmationFlags.displaySubject &&
								<Label>
									{this.props.apiResponseView.subjectText}
								</Label>
							}
							{
								this.isEncryptVisible() &&
								<Stack horizontal verticalAlign='center' horizontalAlign={this.props.apiResponseView.alignRightToLeft ? "end" : "start"} tokens={stringMainPolicySpaces}>
									<ChoiceGroup defaultSelectedKey={this.props.apiResponseView.onSendProcessContex?.settings._triggerEncryptionDefaultOption !== 0 ?
										(this.props.apiResponseView.onSendProcessContex?.settings._triggerEncryptionDefaultOption === 1 ? this.validateEncryptionSelection("Yes", true) : this.validateEncryptionSelection("No", true)) : ""} styles={{ flexContainer: { display: "flex" } }}
										onChange={(_e: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
											if (option !== undefined) {
												this.validateEncryptionSelection(option?.key, false);
											}
										}
										}
										label={this.props.apiResponseView.onSendProcessContex?.settings._stringMainTriggerEncryptionQuestion}
										options={[
											{ key: 'Yes', text: this.props.apiResponseView.onSendProcessContex?.settings._stringMainTriggerEncryptionOptionYes, styles: { field: { marginRight: "15px" } } },
											{ key: 'No', text: this.props.apiResponseView.onSendProcessContex?.settings._stringMainTriggerEncryptionOptionNo }]}                                    >
									</ChoiceGroup>
								</Stack>
							}
							{
								this.isUnprotectedAttachmentsNotificationVisible() &&
								<Label>
									{this.props.unprotectedAttachmentMessage}
								</Label>
							}
							{
								this.isUnprotectedAttachmentsComfirmationVisible() &&
								<Stack style={this.props.apiResponseView.alignRightToLeft ? { alignItems: "flex-end", textAlign: "right" } : { alignItems: "flex-start", paddingBottom: "2px" }}>
									<Checkbox
										boxSide={this.props.apiResponseView.alignRightToLeft ? "end" : "start"}
										disabled={this.settings.denySend}
										label={this.props.unprotectedAttachmentMessage}
										checked={this.settings.confirmUnprotectedAttachmentsCheck}
										onChange={(e) => { this.settings.confirmUnprotectedAttachmentsCheck = (e?.target as HTMLInputElement).checked; this.setSendEnabled(); }}

									/>
								</Stack>
							}
							{
								this.props.confirmationRequired &&
								<div className={classNames.wrapper} style={browserName === WebBrowser.Safari || browserName === WebBrowser.WebKit ? { height: "250px" } : {}} >
									<ScrollablePane
										scrollContainerFocus={true}
										scrollContainerAriaLabel="Sticky component example"
										styles={scrollablePaneStyles}
									>
										<div>
											<Sticky stickyPosition={StickyPositionType.Both}>
												<div role="heading" aria-level={0} className={classNames.sticky}>
													{
														this.props.apiResponseView.unsafeRecipients.isGroupByDomain &&
														<MessageBar messageBarType={MessageBarType.warning} isMultiline={false} styles={this.props.apiResponseView.alignRightToLeft ? messageBarStylesRigth : {}}>
															{
																this.isProcessMaxNrRecipients()
																				? < div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageManyRecipients) }} />
																	: this.props.apiResponseView.unsafeRecipients.toGroup.length > 1 || this.props.apiResponseView.unsafeRecipients.ccGroup.length > 1 || this.props.apiResponseView.unsafeRecipients.bccGroup.length > 1
																					? <div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageMultipleDomainRecipients) }} />
																					: <div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageSingleDomainRecipient) }} />
															}
														</MessageBar>
													}
													{
														!this.props.apiResponseView.unsafeRecipients.isGroupByDomain &&
														<MessageBar messageBarType={MessageBarType.warning} isMultiline={this.isProcessMaxNrRecipients()} styles={this.props.apiResponseView.alignRightToLeft ? messageBarStylesRigth : {}}>
															{
																this.isProcessMaxNrRecipients()
																				? < div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageManyRecipients) }} />
																	: this.props.apiResponseView.unsafeRecipientsCount > 1
																					? < div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageMultipleRecipients) }} />
																					: <div dangerouslySetInnerHTML={{ __html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringMainMessageSingleRecipient) }} />
															}
														</MessageBar>
													}
													{
														this.props.apiResponseView.unsafeAttachmentsCount > 0 &&
														<MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} styles={this.props.apiResponseView.alignRightToLeft ? messageBarStylesRigth : {}}>
															{
																this.props.apiResponseView.onSendProcessContex?.settings._disableConfirmAttachments ?
																	this.props.apiResponseView.onSendProcessContex?.settings._stringMainFilesAttached :
																	(this.props.apiResponseView.unsafeAttachmentsCount > 1 ?
																		this.props.apiResponseView.onSendProcessContex?.settings._stringMainFilesAttachedConfirmed :
																		this.props.apiResponseView.onSendProcessContex?.settings._stringMainFileAttachedConfirmed)
															}
														</MessageBar>
													}
													{
														this.props.apiResponseView.onSendProcessContex.popupForMatchingRecipientsRegexMatched &&
																<div style={{ fontSize: '11px', }} dangerouslySetInnerHTML={{
																	__html: fixupHtml(this.props.apiResponseView.onSendProcessContex?.settings._stringNotifyMatchingRecipientsRegexMatched)
															}} />
													}
												</div>
											</Sticky>
											<Stack tokens={stackTokens}>
												<ConfirmableList
													onChange={(allSelected) => { this.settings.confirmableAllSelected = allSelected; this.setSendEnabled(); }}
													apiResponse={this.props.apiResponseView}
													denySend={this.settings.denySend}
													scanTimedOut={this.props.scanTimedOut}
													dlps={this.props.dlps}
													alignRightToLeft={this.props.apiResponseView.alignRightToLeft}
													onRecipientRemoval={this.handleRecipientRemoval}
													onAttachmentRemoval={this.handleAttachmentRemoval}
													onGroupRemoval={this.handleGroupRemoval}
													isRecipientRemovalDisabled={this.isRecipientRemovalDisabled()}
													isRecipientAttachmentRemovalEnabled={this.isRecipientAttachmentRemovalEnabled()}>
												</ConfirmableList>
											</Stack>
										</div>

										<div>
											<Sticky stickyPosition={StickyPositionType.Both}>
												<div role="heading" aria-level={1} className={this.props.clientKeywordDomainResults.length === 0 ? 'hidden' : classNames.sticky}>
													<MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} styles={this.props.apiResponseView.alignRightToLeft ? messageBarStylesRigth : {}}>
														<Stack>
															{
																!this.props.scanTimedOut && !this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningWrongClientWarning}
																</>
															}
															{
																this.props.scanTimedOut && this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningWrongClientWarning} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTimedOutShort}, {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSendingNotAllowed}
																</>
															}
															{
																this.props.scanTimedOut && !this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningWrongClientWarning} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTimedOutShort}
																</>
															}
															{
																!this.props.scanTimedOut && this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningWrongClientWarning} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSendingNotAllowed}
																</>
															}
															{
																this.props.groupByAttachments.groupByClientKeywordAttachments &&
																<span>
																	{this.props.groupByAttachments.groupByClientKeywordAttachmentsText}
																</span>
															}
														</Stack>
													</MessageBar>
												</div>
											</Sticky>
											{
												this.props.clientKeywordDomainResults.length !== 0 &&
												<Stack tokens={stackTokens}>
													<ClientKeywordDomainResultsList
														confirmText={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningConfirmContentText}
														columnName1={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSource}
														columnName2={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningEmailAttachment}
														columnName3={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningClientData}
														columnName4={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTotalMatches}
														columnName5={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningAllowedDomains}
														results={this.props.clientKeywordDomainResults}
														showTextField={this.settings.showConfirmClientKeywordsText}
														denySend={this.settings.denySend}
														hasAttachments={this.props.apiResponseView.onSendProcessContex?.mailItem.attachmentCount > 0}
														onChangeAction={e => { this.settings.confirmClientKeywordsText = (e.target as HTMLInputElement).value; this.setSendEnabled(); }}
														alignRightToLeft={this.props.apiResponseView.alignRightToLeft}
														pointCursorText={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningPointCursorHere}
														tooLongText={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTooLongToDisplay}
													/>
												</Stack>
											}
										</div>

										<div>
											<Sticky stickyPosition={StickyPositionType.Both}>
												<div role="heading" aria-level={2} className={this.props.contentScanningResults.length === 0 ? 'hidden' : classNames.sticky}>
													<MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} styles={this.props.apiResponseView.alignRightToLeft ? messageBarStylesRigth : {}}>
														<Stack>
															{
																!this.props.scanTimedOut && !this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSensitiveContentFound}
																</>
															}
															{
																this.props.scanTimedOut && this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSensitiveContentFound} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTimedOutShort}, {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSendingNotAllowed}
																</>
															}
															{
																this.props.scanTimedOut && !this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSensitiveContentFound} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTimedOutShort}
																</>
															}
															{
																!this.props.scanTimedOut && this.settings.denySend &&
																<>
																	{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSensitiveContentFound} - {this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSendingNotAllowed}
																</>
															}
															{
																this.props.groupByAttachments.groupByContentScanningAttachments &&
																<span>
																	{this.props.groupByAttachments.groupByContentScanningAttachmentsText}
																</span>
															}
														</Stack>
													</MessageBar>
												</div>
											</Sticky>
											{
												this.props.contentScanningResults.length !== 0 &&
												<Stack tokens={stackTokens}>
													<ContentScanningResultsList
														columnName1={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningRule}
														columnName2={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningSource}
														columnName3={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningEmailAttachment}
														columnName4={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningFirstMatch}
														columnName5={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTotalMatches}
														showCheckbox={this.settings.showConfirmContentScanningCheck}
														showTextField={this.settings.showConfirmContentScanningText}
														labelCheckbox={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningConfirmContent}
														labelTextField={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningConfirmContentText}
														checkboxChecked={this.settings.confirmContentScanningCheck}
														results={this.props.contentScanningResults}
														denySend={this.settings.denySend}
														hasAttachments={this.props.apiResponseView.onSendProcessContex?.mailItem.attachmentCount > 0}
														onChangeCheckbox={(e) => { this.settings.confirmContentScanningCheck = (e?.target as HTMLInputElement).checked; this.setSendEnabled(); }}
														onChangeTextField={e => { this.settings.confirmContentScanningText = (e.target as HTMLInputElement).value; this.setSendEnabled(); }}
														alignRightToLeft={this.props.apiResponseView.alignRightToLeft}
														pointCursorText={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningPointCursorHere}
														tooLongText={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTooLongToDisplay}
													/>
												</Stack>
											}
										</div>
									</ScrollablePane>
								</div>
							}

							{
								this.isPasswordPromptVisible() &&
								<Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft} styles={stackTextFieldStyles} tokens={confirmationStackTokens}>
									<Label>{this.props.apiResponseView.onSendProcessContex?.settings._stringPasswordRequired}</Label>
									<TextField
										required
										type="password"
										autoComplete={"off"}
										styles={textFieldStyles}
										autoFocus={true}
										onChange={e => {
											this.settings.attachmentPassword = (e.target as HTMLInputElement).value;
											if (this.settings.attachmentPassword !== "") {
												this.setState({
													enabledSubmitPassword: true
												});
											} else {
												this.setState({
													enabledSubmitPassword: false
												});
											}
										}}
										onKeyDown={e => {
											let event = (e as React.KeyboardEvent<HTMLDivElement>);
											if (event.key === 'Enter') {
												event.preventDefault();
												event.stopPropagation();
												this.attachmentSendPassword(this.settings.attachmentPassword);
											}
										}}
									/>
									<PrimaryButton onClick={() => this.attachmentSendPassword(this.settings.attachmentPassword)}
										text={this.props.apiResponseView.onSendProcessContex?.settings._stringPasswordSubmit} disabled={!this.state.enabledSubmitPassword} />
								</Stack>
							}
							<Stack reversed={this.props.apiResponseView.alignRightToLeft} className={classNames.notificationArea} tokens={notificationAreaStackTokens}>
								{

									this.props.apiResponseView.onSendProcessContex.scanStarted && this.props.isDlpWaiting &&
									<div className={classNames.progressIndicator} style={this.props.apiResponseView.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
										<ProgressIndicator label={this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningScanningContent} className={classNames.progressIndicator} />
									</div>
								}
								{
									this.props.apiResponseView.onSendProcessContex.scanStarted && !this.props.isDlpWaiting && !this.props.scanTimedOut &&
									this.props.clientKeywordDomainResults.length === 0 && this.props.contentScanningResults.length === 0 &&
									<Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft}>
										<Label>
											{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningComplete}
										</Label>
										<FontIcon iconName="CompletedSolid" className={classNames.completed} />
									</Stack>
								}
								{
									this.props.apiResponseView.onSendProcessContex.scanStarted && !this.props.isDlpWaiting && this.props.scanTimedOut &&
									this.props.clientKeywordDomainResults.length === 0 && this.props.contentScanningResults.length === 0 &&
									<Stack horizontal reversed={this.props.apiResponseView.alignRightToLeft}>
										<Label>
											{this.props.apiResponseView.onSendProcessContex?.settings._stringContentScanningTimedOutLong}
										</Label>
										<FontIcon iconName="WarningSolid" className={classNames.warning} />
									</Stack>
								}
								{
									this.props.apiResponseView.onSendProcessContex?.confirmationFlags.showPolicyText &&
									<div style={{ fontSize: '11px', }}
										dangerouslySetInnerHTML={{
											__html: InjectLinkToString("%",
												this.props.apiResponseView.onSendProcessContex?.settings._stringMainPolicy,
												this.props.apiResponseView.onSendProcessContex?.settings._policyLink)
										}} />
								}
							</Stack>

						</Stack>
					</Panel>
				}
			</>
		);
	}
}
