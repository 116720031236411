import React from 'react';
import { Checkbox, IStackTokens, Label, Separator, Stack } from '@fluentui/react';
import { IConfirmableProps, IConfirmableStates, IRecipientConfirmable, IAttachmentConfirmable } from './ConfirmableTypes';
import GroupRecipients from '../GroupRecipient/GroupRecipients';
import AttachmentConfirmable from '../Attachment/AttachmentConfirmable';
import { DlpItemStatus, RecipientSendType } from '../Backend/BackendTypes';
import { Modules } from '../../LogConfiguration';
import { safeSendLogging } from '../../lib/Logger';
import { AttachmentFileSizeUnitsStrings } from '../Attachment/AttachmentTypes';

const logger = safeSendLogging.getLogger(Modules.SafeSend_ConfirmableList);

const stackTokensRecipientsCheck: IStackTokens = {
    padding: `${0}px ${30}px ${0}px ${30}px`,
};

const stackTokensFilesCheck: IStackTokens = {
    padding: `${0}px ${24}px ${0}px ${28}px`,
};

const domainColors = ["#69797E", "#A4262C", "#004E8C", "#0B6A0B", "#986F0B"];
const colorByDomain = {};


export default class ConfirmableList extends React.Component<IConfirmableProps, IConfirmableStates> {
    constructor(props) {
        super(props);
        this.state = { 
            toConfirmableList: [],
            ccConfirmableList: [],
            bccConfirmableList: [],
            attachmentConfirmableList: [],
            allChecked: false,
            checked: false,
            updatedDlpstatus: false,
         };
        this.handleChangeSelectAll = this.handleChangeSelectAll.bind(this);
        this.handleChangeConfirmable = this.handleChangeConfirmable.bind(this);
        this.handleRemoveRecipient = this.handleRemoveRecipient.bind(this);
        this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
        this.handleRemoveRecipientGroup = this.handleRemoveRecipientGroup.bind(this);
    }

    static getDerivedStateFromProps(nextProps: IConfirmableProps, prevState: IConfirmableStates) {
        if (prevState.updatedDlpstatus) {
            return ({ updatedDlpstatus: false })
        }

        if (nextProps.dlps.length > 0 && !prevState.updatedDlpstatus) {
            logger.debug('Fill from DLP items');
            nextProps.dlps.forEach(dlp => {
                prevState.attachmentConfirmableList.every(item => {
                    if (item.dlp.id === dlp.id) {
                        item.dlp.status = dlp.status;
                        item.dlp.statusString = dlp.statusString;
                        return false;
                    }

                    return true;
                });
            });

            return ({ attachmentConfirmableList: prevState.attachmentConfirmableList, updatedDlpstatus: true });
        }

        if (Object.keys(nextProps.apiResponse).length > 0 && prevState.toConfirmableList.length === 0 && prevState.ccConfirmableList.length === 0 && prevState.bccConfirmableList.length === 0) {
            logger.debug('Fill from API response');
            let precheckRecipients = nextProps.apiResponse.onSendProcessContex.settings._precheckRecipients;
            let singleColorIndex = 0;

            let confirmableListTo: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.to.map((recipient) => {
                if (!colorByDomain[recipient.domain]) {
                    colorByDomain[recipient.domain] = domainColors[singleColorIndex];
                    singleColorIndex++;
                    if (singleColorIndex >= domainColors.length) {
                        singleColorIndex -= domainColors.length;
                    }
				}
                return (
                    {
                        id: recipient.id,
                        single: true,
                        checked: precheckRecipients,
                        displayName: recipient.displayName,
                        color: colorByDomain[recipient.domain],
                        childrens: [],
                        isRemoved: false,
                        emailAddress: recipient.email,
                        isList: recipient.isList,
                    }
                ) as IRecipientConfirmable
            })

            let confirmableListCc: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.cc.map((recipient) => {
                if (!colorByDomain[recipient.domain]) {
                    colorByDomain[recipient.domain] = domainColors[singleColorIndex];
                    singleColorIndex++;
                    if (singleColorIndex >= domainColors.length) {
                        singleColorIndex -= domainColors.length;
                    }
                }
                return (
                    {
                        id: recipient.id,
                        single: true,
                        checked: precheckRecipients,
                        displayName: recipient.displayName,
                        color: colorByDomain[recipient.domain],
                        childrens: [],
                        isRemoved: false,
                        emailAddress: recipient.email,
                        isList: recipient.isList,
                    }
                ) as IRecipientConfirmable
            })

            let confirmableListBcc: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.bcc.map((recipient) => {
                if (!colorByDomain[recipient.domain]) {
                    colorByDomain[recipient.domain] = domainColors[singleColorIndex];
                    singleColorIndex++;
                    if (singleColorIndex >= domainColors.length) {
                        singleColorIndex -= domainColors.length;
                    }
                }
                return (
                    {
                        id: recipient.id,
                        single: true,
                        checked: precheckRecipients,
                        displayName: recipient.displayName,
                        color: colorByDomain[recipient.domain],
                        childrens: [],
                        isRemoved: false,
                        emailAddress: recipient.email,
                        isList: recipient.isList,
                    }
                ) as IRecipientConfirmable
            })

            let colorIndex = 0;

            let confirmableListToGroup: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.toGroup.map((item) => {
                colorIndex++;
                if (colorIndex >= domainColors.length) {
                    colorIndex -= domainColors.length;
                }
                return (
                    {
                        id: item.id,
                        single: false,
                        checked: precheckRecipients,
                        displayName: item.domain,
                        color: domainColors[colorIndex],
                        childrens: item.recipients.map((recipient) => ({ id: recipient.id, displayName: recipient.displayName, emailAddress: recipient.email })),
                        isRemoved: false,
                    }
                ) as IRecipientConfirmable
            })

            let confirmableListCcGroup: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.ccGroup.map((item) => {
                colorIndex++;
                if (colorIndex >= domainColors.length) {
                    colorIndex -= domainColors.length;
                }
                return (
                    {
                        id: item.id,
                        single: false,
                        checked: precheckRecipients,
                        displayName: item.domain,
                        color: domainColors[colorIndex],
                        childrens: item.recipients.map((recipient) => ({ id: recipient.id, displayName: recipient.displayName, emailAddress: recipient.email })),
                        isRemoved: false,
                    }
                ) as IRecipientConfirmable
            })

            let confirmableListBccGroup: IRecipientConfirmable[] = nextProps.apiResponse.unsafeRecipients?.bccGroup.map((item) => {
                colorIndex++;
                if (colorIndex >= domainColors.length) {
                    colorIndex -= domainColors.length;
                }
                return (
                    {
                        id: item.id,
                        single: false,
                        checked: precheckRecipients,
                        displayName: item.domain,
                        color: domainColors[colorIndex],
                        childrens: item.recipients.map((recipient) => ({ id: recipient.id, displayName: recipient.displayName, emailAddress: recipient.email })),
                        isRemoved: false,
                    }
                ) as IRecipientConfirmable
            })

            let settings = nextProps.apiResponse.onSendProcessContex.settings;
            let confirmableAttachments: IAttachmentConfirmable[] = nextProps.apiResponse.unsafeAttachments?.map((item) => (
                {
                    id: item.id,
                    checked: precheckRecipients,
                    displayName: item.filename,
                    dlp: {
                        id: item.id,
                        status: nextProps.apiResponse.onSendProcessContex.scanStarted ? (item.contentType === "none" ? DlpItemStatus.Unsupported : DlpItemStatus.Scanning) : DlpItemStatus.ScanNotStarted,
                        statusString: nextProps.apiResponse.onSendProcessContex.scanStarted ? (item.contentType === "none" ? settings._stringAttachmentStatusUnsupported : settings._stringAttachmentStatusScanning) : settings._stringAttachmentStatusScanNotStarted
                    },
                    isRemoved: false,
                    size: item.size,
                }
            ))

            nextProps.onChange(precheckRecipients);

            return ({
                allChecked: precheckRecipients,
                checked: precheckRecipients,
                toConfirmableList: confirmableListTo.concat(confirmableListToGroup),
                ccConfirmableList: confirmableListCc.concat(confirmableListCcGroup),
                bccConfirmableList: confirmableListBcc.concat(confirmableListBccGroup),
                attachmentConfirmableList: confirmableAttachments
            })
        }      

        return null
    }

    async handleChangeSelectAll(e) {
        let checked: boolean = (e?.target as HTMLInputElement).checked;

        if (this.areAllRecipientsRemoved()){
            return;
        }
        
        this.state.toConfirmableList.forEach(item => {
            if (!item.isRemoved){
                item.checked = checked;
            }
        });

        this.state.ccConfirmableList.forEach(item => {
            if (!item.isRemoved){
                item.checked = checked;
            }
        });

        this.state.bccConfirmableList.forEach(item => {
            if (!item.isRemoved){
                item.checked = checked;
            }
        });
        
        this.state.attachmentConfirmableList.forEach(item => {
            if (!item.isRemoved)
            {
                item.checked = checked; 
            }
        });

        await this.setState({
            allChecked: checked,
            checked: checked,
            toConfirmableList: this.state.toConfirmableList,
            ccConfirmableList: this.state.ccConfirmableList,
            bccConfirmableList: this.state.bccConfirmableList,
            attachmentConfirmableList: this.state.attachmentConfirmableList
        });

        this.props.onChange(this.state.allChecked);
    }

    async handleChangeConfirmable(id: string, checked: boolean) {
        let notFound = false;
        notFound = this.state.toConfirmableList.every(item => {
            if (item.id === id) {
                item.checked = item.isRemoved ? false : checked;
                return false;
            }

            return true;
        });

        if (notFound) {
            notFound = this.state.ccConfirmableList.every(item => {
                if (item.id === id) {
                    item.checked = item.isRemoved ? false : checked;
                    return false;
                }

                return true;
            });
        }            

        if (notFound) {
            notFound = this.state.bccConfirmableList.every(item => {
                if (item.id === id) {
                    item.checked = item.isRemoved ? false : checked;
                    return false;
                }

                return true;
            });
        }

        if (notFound) {
            this.state.attachmentConfirmableList.every(item => {
                if (item.id === id) {
                    item.checked = item.isRemoved ? false : checked;
                    return false;
                }

                return true;
            });
        }

        let allChecked = this.state.toConfirmableList.every(item => { return item.checked || item.isRemoved; });
        if (allChecked) {
            allChecked = this.state.ccConfirmableList.every(item => { return item.checked || item.isRemoved; });
            if (allChecked) {
                allChecked = this.state.bccConfirmableList.every(item => { return item.checked || item.isRemoved ; });
                if (allChecked) {
                    allChecked = this.state.attachmentConfirmableList.every(item => { return item.checked || item.isRemoved; });
                }
            }
        }
        
        allChecked = this.areAllRecipientsRemoved() ? false : allChecked;
        
        await this.setState({
            allChecked: allChecked,
            checked: allChecked,
            toConfirmableList: this.state.toConfirmableList,
            ccConfirmableList: this.state.ccConfirmableList,
            bccConfirmableList: this.state.bccConfirmableList,
            attachmentConfirmableList: this.state.attachmentConfirmableList,
        });

        this.props.onChange(this.state.allChecked);
    }

    async handleRemoveAttachment(id: string)
    {
        const updatedAttachment = this.state.attachmentConfirmableList.find(attachment => attachment.id === id);

        if (!updatedAttachment) {
            return;
        };

        const updatedList = this.state.attachmentConfirmableList.map(attachment => {
            if (attachment.id === id)
            {
                attachment.isRemoved = !attachment.isRemoved;
                attachment.checked = false;
            }

            return attachment;
        });

        this.setState({
            ...this.state,
            attachmentConfirmableList: updatedList,
        });

        this.props.onAttachmentRemoval(id, updatedAttachment.isRemoved, updatedAttachment.size, updatedAttachment.displayName);
        this.handleChangeConfirmable(id, updatedAttachment.checked);
    }

    async handleRemoveRecipientGroup(domain: string){
        const recipientsRemoved : string[] = [];
        const updatedState = this.state.toConfirmableList.map(group => {
            if (group.displayName === domain)
            {
                group.checked = false
                group.isRemoved = !group.isRemoved;
                group.childrens.forEach(recipient => {
                    recipientsRemoved.push(recipient.emailAddress);
                });
            }
            return group;
        });

        const updatedGroup = updatedState.find(group => group.displayName === domain);
        if (updatedGroup){
            this.setState({
                ...this.state,
                toConfirmableList: updatedState,
            });
    
            this.props.onGroupRemoval(recipientsRemoved, updatedGroup.isRemoved);
        }        
    }
    
    async handleRemoveRecipient(id: string, recipientType: RecipientSendType){
        // creating a propertyAccessor obj that will basically return the name of the list we need to update when updating the state;
        const propertyAccessorHelper = {
            [RecipientSendType.TO]: "toConfirmableList",
            [RecipientSendType.CC]: "ccConfirmableList",
            [RecipientSendType.BCC]: "bccConfirmableList",
        } as const;

        //creating a map to get a specific list of recipients based on its recipientType
        const map = new Map<RecipientSendType, IRecipientConfirmable[]>();
        map.set(RecipientSendType.TO, this.state.toConfirmableList);
        map.set(RecipientSendType.CC, this.state.ccConfirmableList)
        map.set(RecipientSendType.BCC, this.state.bccConfirmableList)

        const listToUpdate = map.get(recipientType);
        const recipientToUpdate = listToUpdate.find(recipient => recipient.id === id);
        if(recipientToUpdate)
        {
            const updatedList = listToUpdate.map(recipient =>{
                if(recipient.id === id)
                {
                    recipient.isRemoved = !recipient.isRemoved;
                    recipient.checked = false;
                }
    
                return recipient;
            });
    
            this.setState({
                ...this.state,
                [propertyAccessorHelper[recipientType]]: updatedList,
            });

            this.props.onRecipientRemoval(recipientToUpdate.emailAddress!, recipientType, recipientToUpdate.isRemoved);
            this.handleChangeConfirmable(id, recipientToUpdate.checked);
        }
    }

    areAllRecipientsRemoved = () => {
        const allUnsafeRecipientsRemoved = this.state.toConfirmableList.every(item => item.isRemoved)
        && this.state.ccConfirmableList.every(item =>item.isRemoved)
        && this.state.bccConfirmableList.every(item => item.isRemoved);
        
        let allRecipientsRemoved = allUnsafeRecipientsRemoved && !this.props.apiResponse.hasSafeRecipients;
        return allRecipientsRemoved;
    }

    areAllAttachmentsRemoved = () => this.state.attachmentConfirmableList.every(item => item.isRemoved);
    
    getAttachmentsFileSizeStrings = () : AttachmentFileSizeUnitsStrings => {
        return {
            Bytes: this.props.apiResponse.onSendProcessContex.settings._stringFileSizeBytes,
            kB: this.props.apiResponse.onSendProcessContex.settings._stringFileSizeKB,
            MB: this.props.apiResponse.onSendProcessContex.settings._stringFileSizeMB,
        }
    }

    render() {
        return (
        <Stack style={this.props.alignRightToLeft ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}>
				  {
					  this.props.apiResponse.onSendProcessContex?.confirmationFlags.showSelectAll && !this.props.apiResponse.onSendProcessContex?.settings._selectAllPositionOnBottom &&
                 <Checkbox
                    boxSide={this.props.alignRightToLeft ? "end" : "start"}
                    disabled={this.props.denySend}
                    label={this.props.apiResponse.onSendProcessContex?.settings._stringMainSelectAll}
                    checked={this.state.checked}
                    onChange={this.handleChangeSelectAll} />
            }
            <Stack style={this.props.alignRightToLeft ? { alignItems: "flex-end", width: "100%" } : { alignItems: "flex-start", width: "100%" }}>
                {
                    this.state.toConfirmableList.length > 0 &&
                    <>
                        <Label>{this.props.apiResponse.onSendProcessContex?.settings._stringMainEmailTO}</Label>
                        <Stack wrap tokens={stackTokensRecipientsCheck}>
                            {
                                this.state.toConfirmableList.map(item =>
                                    <GroupRecipients
                                        onChange={this.handleChangeConfirmable}
                                        disabled={this.props.denySend}
                                        checked={item.checked}
                                        id={item.id}
                                        key={item.id}
                                        text={item.displayName}
                                        single={item.single}
                                        recipients={item.childrens}
                                        color={item.color}
                                        colorDomains={this.props.apiResponse.onSendProcessContex?.confirmationFlags.colorDomains}
										alignRightToLeft={this.props.alignRightToLeft}
										expanded={this.props.apiResponse.onSendProcessContex.settings._expandListsByDefault}
                                        isRemoved={item.isRemoved}
                                        handlePersonaRemoved={this.handleRemoveRecipient}
                                        recipientType={RecipientSendType.TO}
                                        handleRecipientGroupRemoved={this.handleRemoveRecipientGroup}
                                        isRecipientRemovalDisabled= {this.props.isRecipientRemovalDisabled} 
                                        isRecipientAttachmentRemovalEnabled={this.props.isRecipientAttachmentRemovalEnabled} />
                                )
                            }
                        </Stack>
                    </>
                }
                {
                    this.state.ccConfirmableList.length > 0 &&
                    <>
                        <Label>{this.props.apiResponse.onSendProcessContex?.settings._stringMainEmailCC}</Label>
                        <Stack wrap tokens={stackTokensRecipientsCheck}>
                            {
                                this.state.ccConfirmableList.map(item => 
                                    <GroupRecipients
                                        onChange={this.handleChangeConfirmable}
                                        disabled={this.props.denySend}
                                        checked={item.checked}
                                        id={item.id}
                                        key={item.id}
                                        text={item.displayName}
                                        single={item.single}
                                        recipients={item.childrens}
                                        color={item.color}
                                        colorDomains={this.props.apiResponse.onSendProcessContex?.confirmationFlags.colorDomains}
										alignRightToLeft={this.props.alignRightToLeft}
										expanded={this.props.apiResponse.onSendProcessContex.settings._expandListsByDefault}
                                        isRemoved={item.isRemoved}
                                        handlePersonaRemoved={this.handleRemoveRecipient}
                                        recipientType={RecipientSendType.CC}
                                        handleRecipientGroupRemoved={this.handleRemoveRecipientGroup}
                                        isRecipientRemovalDisabled = {this.props.isRecipientRemovalDisabled} 
                                        isRecipientAttachmentRemovalEnabled={this.props.isRecipientAttachmentRemovalEnabled} />
                                )
                            }
                        </Stack>
                    </>
                }
                {
                    this.state.bccConfirmableList.length > 0 &&
                    <>
                        <Label>{this.props.apiResponse.onSendProcessContex?.settings._stringMainEmailBCC}</Label>
                        <Stack wrap tokens={stackTokensRecipientsCheck}>
                            {
                                this.state.bccConfirmableList.map(item =>
                                    <GroupRecipients
                                        onChange={this.handleChangeConfirmable}
                                        disabled={this.props.denySend}
                                        checked={item.checked}
                                        id={item.id}
                                        key={item.id}
                                        text={item.displayName}
                                        single={item.single}
                                        recipients={item.childrens}
                                        color={item.color}
                                        colorDomains={this.props.apiResponse.onSendProcessContex?.confirmationFlags.colorDomains}
										alignRightToLeft={this.props.alignRightToLeft}
										expanded={this.props.apiResponse.onSendProcessContex.settings._expandListsByDefault}
                                        isRemoved={item.isRemoved}
                                        handlePersonaRemoved={this.handleRemoveRecipient}
                                        recipientType={RecipientSendType.BCC}
                                        handleRecipientGroupRemoved={this.handleRemoveRecipientGroup}
                                        isRecipientRemovalDisabled = {this.props.isRecipientRemovalDisabled}
                                        isRecipientAttachmentRemovalEnabled={this.props.isRecipientAttachmentRemovalEnabled} />
                                )
                            }
                        </Stack>
                    </>
                }
                {
                    this.state.attachmentConfirmableList.length > 0 &&
                    <>
                        {   
                            this.props.apiResponse.onSendProcessContex?.settings._showAttachmentsSeparator &&
                            <Separator styles={{root: {width: "100%"}}} />
                        }
                        <Label>{this.props.apiResponse.onSendProcessContex?.settings._stringMainFilesAttachedHeader}</Label>
                        <Stack wrap tokens={stackTokensFilesCheck}>
                            {this.state.attachmentConfirmableList.map(item =>
                                <div className='confirmable-list'
                                    key={item.id}>
                                    {
                                        <div>
                                            <AttachmentConfirmable
                                                showCheckBox={true}
                                                disabled={this.props.denySend}
                                                onChange={this.handleChangeConfirmable}
                                                checked={item.checked}
                                                id={item.id}
                                                key={item.id}
                                                filename={item.displayName}
                                                alignRightToLeft={this.props.alignRightToLeft}
                                                isRemoved = {item.isRemoved}
                                                handleAttachmentRemoved={this.handleRemoveAttachment}
                                                dlp={item.dlp}
                                                scanTimedOut={this.props.scanTimedOut}
                                                isRecipientAttachmentRemovalEnabled={this.props.isRecipientAttachmentRemovalEnabled}
                                                fileSize={item.size}
                                                fileSizeUnitStrings={this.getAttachmentsFileSizeStrings()}>
                                            </AttachmentConfirmable>
                                        </div>
                                    }
                                </div>
                            )}
                        </Stack>
                    </>
                }
			</Stack>
				{
					this.props.apiResponse.onSendProcessContex?.confirmationFlags.showSelectAll 
                    && this.props.apiResponse.onSendProcessContex?.settings._selectAllPositionOnBottom 
                    && <Checkbox
                            boxSide={this.props.alignRightToLeft ? "end" : "start"}
                            disabled={this.props.denySend}
                            label={this.props.apiResponse.onSendProcessContex?.settings._stringMainSelectAll}
                            checked={this.state.checked}
                            onChange={this.handleChangeSelectAll} />
			    }
        </Stack>
        );
    }
}