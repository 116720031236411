import * as React from "react";
import { Checkbox, CheckboxVisibility, DetailsHeader, DetailsList, IColumn, IDetailsHeaderStyles, IDetailsListProps, IStackStyles, IStackTokens, ITextFieldStyles, Label, SelectionMode, Stack, TextField } from "@fluentui/react";
import { ContentScanningResultView, DlpResultSourceTypeEnum } from "../Backend/BackendTypes";
import { nameof } from '../../lib/Utils'
import AttachmentConfirmable from "../Attachment/AttachmentConfirmable";
import AttachmentDlp from "../Attachment/AttachmentDlp";

const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = { root: { height: '35px', paddingTop: '2px' } }
const textFieldStyles: Partial<ITextFieldStyles> = { root: { width: 80, position: 'relative', paddingLeft: '5px' } };

const confirmationStackTokens: IStackTokens = {
    childrenGap: 5,
};

const stackTextFieldStyles: Partial<IStackStyles> = {
    root: {
        paddingTop: '15px'
    }
};
const stackTableStyles: Partial<IStackStyles> = {
    root: {
        border: '1px solid #ccc'
    }
};
const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
    if (props) {
        return <DetailsHeader {...props} styles={detailsHeaderStyles} />;
    }
    return null;
};

const contentScanningResultsList = (props:
    {
        columnName1: string,
        columnName2: string,
        columnName3: string,
        columnName4: string,
        columnName5: string,
        results: ContentScanningResultView[],
        denySend: boolean,
        hasAttachments: boolean,
        checkboxChecked: boolean,
        showTextField: boolean,        
        showCheckbox: boolean,
        labelTextField: string,
        labelCheckbox: string,
        onChangeTextField: any
        onChangeCheckbox: any,
        alignRightToLeft: boolean,
        pointCursorText: string,
        tooLongText: string
    }) => {
    let columns: IColumn[] = [
        {
            key: 'column1',
            name: props.columnName1,
            fieldName: `${nameof<ContentScanningResultView>(c => c.ruleName)}`,
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ContentScanningResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.ruleName}
                </Stack>
            )
        },
        {
            key: 'column2',
            name: props.columnName2,
            fieldName: `${nameof<ContentScanningResultView>(c => c.sourceTypeString)}`,
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ContentScanningResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.sourceTypeString}
                </Stack>
            )
        },
        {
            key: 'column3',
            name: props.columnName3,
            fieldName: `${nameof<ContentScanningResultView>(c => c.attachmentPath)}`,
            minWidth: 100,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ContentScanningResultView) => (
                <Stack style={props.alignRightToLeft ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}>
                    {
                        !item.groupBy && (item.sourceType === DlpResultSourceTypeEnum.Attachment || item.sourceType === DlpResultSourceTypeEnum.Filename || item.sourceType === DlpResultSourceTypeEnum.Body) &&
                        <AttachmentConfirmable
                            id={item.key}
                            key={item.key}
                            filename={item.attachmentPath}
                            alignRightToLeft={props.alignRightToLeft}
                            showCheckBox={false} checked={false} disabled={false} onChange={(_checked) => { }}
                            isRemoved={false}>
                        </AttachmentConfirmable>
                    }
                    {
                        item.groupBy && !item.tooLong &&
                        <AttachmentDlp
                            pointCursorText={props.pointCursorText}
                            files={item.files}
                            alignRightToLeft={props.alignRightToLeft}
                            useTooltip={item.useTooltip}
                        >
                        </AttachmentDlp>
                    }
                    {
                        item.groupBy && item.tooLong &&
                        <span>[{props.tooLongText}]</span>
                    }
                </Stack>
            )
        },
        {
            key: 'column4',
            name: props.columnName4,
            fieldName: `${nameof<ContentScanningResultView>(c => c.firstMatch)}`,
            minWidth: 80,
            maxWidth: 210,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ContentScanningResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.firstMatch}
                </Stack>
            )
        },
        {
            key: 'column5',
            name: props.columnName5,
            fieldName: `${nameof<ContentScanningResultView>(c => c.matchCount)}`,
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ContentScanningResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.matchCount}
                </Stack>
            )
        },
    ];

    if (!props.hasAttachments) {
        columns.splice(2, 1);
    } else {
        if (!props.results.some(r => r.attachmentPath !== null)) {
            columns.splice(2, 1);
		}
    }

    return (
        <>
            <Stack styles={stackTableStyles}>
                <DetailsList
                    columns={props.alignRightToLeft ? columns.reverse() : columns}
                    items={props.results}
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    disableSelectionZone={false}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsHeader={_onRenderDetailsHeader}
                >
                </DetailsList>
            </Stack>
            {
                props.showTextField &&
                <Stack horizontal reversed={props.alignRightToLeft} styles={stackTextFieldStyles} tokens={confirmationStackTokens}>
                    <Label >{props.labelTextField} </Label>
                    <TextField
                        required
                        styles={textFieldStyles}
                        autoComplete={"off"}
                        disabled={props.denySend}
                        onChange={props.onChangeTextField}
                    />
                </Stack>
            }
            {
                props.showCheckbox &&
                <Stack style={props.alignRightToLeft ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}>
                    <Checkbox
                        boxSide={props.alignRightToLeft ? "end" : "start"}
                        styles={stackTextFieldStyles}
                        label={props.labelCheckbox}
                        checked={props.checkboxChecked}
                        disabled={props.denySend}
                        onChange={props.onChangeCheckbox}
                    />
                </Stack>
            }
        </>
    );
}

export default contentScanningResultsList;
