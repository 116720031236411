import * as React from "react";
import { DetailsList, IColumn, SelectionMode, CheckboxVisibility, IDetailsHeaderStyles, IDetailsListProps, DetailsHeader} from '@fluentui/react/lib/DetailsList';
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { Stack, Label, IStackStyles, IStackTokens } from "@fluentui/react";
import { ClientKeywordDomainResultView, DlpResultSourceTypeEnum } from "../Backend/BackendTypes";
import { nameof } from '../../lib/Utils'
import AttachmentDlp from "../Attachment/AttachmentDlp";
import AttachmentConfirmable from "../Attachment/AttachmentConfirmable";

const textFieldStyles: Partial<ITextFieldStyles> = { root: { width: 80, position: 'relative', paddingLeft: '5px' } };
const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = { root: { height: '35px', paddingTop: '2px' } };
const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
    if (props) {
        return <DetailsHeader {...props} styles={detailsHeaderStyles} />;
    }

    return null;
};

const confirmationStackTokens: IStackTokens = {
    childrenGap: 5,
};

const stackTableStyles: Partial<IStackStyles> = {
    root: {
        border: '1px solid #ccc'
    }
};
const stackTextFieldStyles: Partial<IStackStyles> = {
    root: {
        paddingTop: '15px'
    }
};

const clientKeywordDomainResultsList = (props:
    {
        columnName1: string,
        columnName2: string,
        columnName3: string,
        columnName4: string,
        columnName5: string,
        confirmText: string,
		results: ClientKeywordDomainResultView[],
		showTextField: boolean,
        denySend: boolean,
        hasAttachments: boolean,
        onChangeAction: any,
        alignRightToLeft: boolean,
        pointCursorText: string,
        tooLongText: string
    }) => {
    let columns: IColumn[] = [
        {
            key: 'column1',
            name: props.columnName1,
            fieldName: `${nameof<ClientKeywordDomainResultView>(c => c.sourceTypeString)}`,
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ClientKeywordDomainResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.sourceTypeString}
                </Stack>
            )
        },
        {
            key: 'column2',
            name: props.columnName2,
            fieldName: `${nameof<ClientKeywordDomainResultView>(c => c.attachmentPath)}`,
            minWidth: 100,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ClientKeywordDomainResultView) => (
                <Stack style={props.alignRightToLeft ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}>
                    {
                        !item.groupBy && (item.sourceType === DlpResultSourceTypeEnum.Attachment || item.sourceType === DlpResultSourceTypeEnum.Filename || item.sourceType === DlpResultSourceTypeEnum.Body) &&
                        <AttachmentConfirmable
                            id={item.key}
                            key={item.key}
                            filename={item.attachmentPath}
                            alignRightToLeft={props.alignRightToLeft}
                            showCheckBox={false}
                            checked={false}
                            disabled={false}
                            onChange={(_checked) => { }}
                            isRemoved={false}
                        />
                        
                    }
                    {
                        item.groupBy && !item.tooLong &&
                        <AttachmentDlp
                            pointCursorText={props.pointCursorText}
                            files={item.files}
                            alignRightToLeft={props.alignRightToLeft}
                            useTooltip={item.useTooltip}
                        >
                        </AttachmentDlp>
                    }
                    {
                        item.groupBy && item.tooLong &&
                        <span>[{props.tooLongText}]</span>
                    }
                </Stack>
            )
        },
        {
            key: 'column3',
            name: props.columnName3,
            fieldName: `${nameof<ClientKeywordDomainResultView>(c => c.clientKeyword)}`,
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ClientKeywordDomainResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.clientKeyword}
                </Stack>
            )
        },
        {
            key: 'column4',
            name: props.columnName4,
            fieldName: `${nameof<ClientKeywordDomainResultView>(c => c.matchCount)}`,
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ClientKeywordDomainResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.matchCount}
                </Stack>
            )
        },
        {
            key: 'column5',
            name: props.columnName5,
            fieldName: `${nameof<ClientKeywordDomainResultView>(c => c.allowedDomain)}`,
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            styles: {
                cellTitle: props.alignRightToLeft ? { flexDirection: "row-reverse" } : {}
            },
            onRender: (item: ClientKeywordDomainResultView) => (
                <Stack style={props.alignRightToLeft ? { textAlign: "right" } : { textAlign: "left" }}>
                    {item.allowedDomain}
                </Stack>
            )
        },        
    ];

    if (!props.hasAttachments) {
        columns.splice(1, 1);
    } else {
        if (!props.results.some(r => r.attachmentPath !== null)) {
            columns.splice(1, 1);
        }
    }

    return (
        <>
            <Stack styles={stackTableStyles}>
                <DetailsList
                    items={props.results}
                    columns={props.alignRightToLeft ? columns.reverse() : columns}
                    compact={true}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    disableSelectionZone={false}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsHeader={_onRenderDetailsHeader}
                        >
                </DetailsList>
			</Stack>
			{
				props.showTextField &&
				<Stack horizontal reversed={props.alignRightToLeft} styles={stackTextFieldStyles} tokens={confirmationStackTokens}>
					<Label >{props.confirmText} </Label>
					<TextField
                        required
                        autoComplete={"off"}
                        styles={textFieldStyles}
                        disabled={props.denySend}
                        onChange={props.onChangeAction}
					/>
					</Stack>
			}
        </>
    );
}

export default clientKeywordDomainResultsList;