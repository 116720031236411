import { Checkbox, FontIcon, IconButton, IIconProps, ILabelStyles, Label, mergeStyles, mergeStyleSets, SharedColors, Spinner, SpinnerType, Stack, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { AttachmentConfirmableProps, AttachmentConfirmableStates, AttachmentFileSize } from './AttachmentTypes';
import { Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ConfirmableLabel from '../Shared/ConfirmableLabel';
import { DlpItemStatus } from '../Backend/BackendTypes';

const iconClass = mergeStyles({
    padding: '0 4px 0 4px',
    fontSize: '16px',
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
});

const classNames = mergeStyleSets({
    fileSizeContainer:{
        padding: "0 0 0 1em",
    },
    fileIconImg: {
        align: "middle",
        width: 20,
        height: 20,
        padding: '0px 4px 0px 4px',
    },
    removableIconContainer: { padding: '0 2px 0 2px'},
    error: [{ color: 'red', cursor: 'default' }, iconClass],
    warning: [{ color: '#ffc107', cursor: 'default' }, iconClass],
    info: [{ color: '#6c757d', cursor: 'default' }, iconClass],
    completed: [{ color: 'green', cursor: 'default' }, iconClass],
});

export default class AttachmentConfirmable extends React.Component<AttachmentConfirmableProps, AttachmentConfirmableStates> {
    constructor(props) {
        super(props);
        this.state = {
            stateUpdate: false,
            checked: false,
            fileSize: this.props.fileSize ? this.ParseFileSize(this.props.fileSize) : undefined,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    static getDerivedStateFromProps(nextProps: AttachmentConfirmableProps, prevState: AttachmentConfirmableStates) {
        if (prevState.stateUpdate) {
            return ({ stateUpdate: false })
        }

        if (nextProps.checked !== prevState.checked) {
            return ({ checked: nextProps.checked })
        }

        return null
    }


    ParseFileSize(fileSize: number){
        let result : AttachmentFileSize = {
            size : fileSize,
            unitType: this.props.fileSizeUnitStrings?.Bytes
        };

        if (fileSize.toString().length >= 7)
        {
            result.size=this.fileSizeToMB(fileSize);
            result.unitType = this.props.fileSizeUnitStrings?.MB;
        }
        else if (fileSize.toString().length >= 4)
        {
            result.size = this.fileSizeToKB(fileSize);
            result.unitType = this.props.fileSizeUnitStrings?.kB;
        }

        return result;
    }

    fileSizeToMB(fileSize: number){
        return Math.round(fileSize / (1024 ** 2));
    }

    fileSizeToKB(fileSize: number){
        return Math.round(fileSize / (1024));
    }

    handleChange(e) {
        let checked = (e?.target as HTMLInputElement).checked;
        this.setState({
            checked: checked,
            stateUpdate: true
        });
        this.props.onChange(this.props.id, checked);
    }

    handleRemove() {
        const {handleAttachmentRemoved} = this.props;
        if (handleAttachmentRemoved)
        {
            handleAttachmentRemoved(this.props.id);
        }
    }

    shouldRenderRemovableIcons(){
        if (!this.props.isRecipientAttachmentRemovalEnabled){
            return false;
        }

        if (!this.props.handleAttachmentRemoved)
        {
            return false;
        }

        return true;
    }

    removeIcon: IIconProps = { iconName: "Cancel", styles:{root: {color: SharedColors.red20}}};
    AddIcon: IIconProps = { iconName: "Add", styles: {root: {color: SharedColors.blue10}}};
    removedLabelStyles: ILabelStyles = {root:{ color: SharedColors.red20, textDecoration: "line-through"}};

    render() {
        return (
            <Stack reversed={this.props.alignRightToLeft} horizontal disableShrink verticalAlign='center'>
                {
                    this.props.showCheckBox &&
                        <Checkbox disabled={this.props.disabled || this.props.isRemoved} onChange={this.handleChange} checked={this.state.checked} />
                }
                { this.props.filename !== null &&
                    <Icon className={classNames.fileIconImg} {...getFileTypeIconProps({ extension: this.props.filename.split('.').pop(), imageFileType: 'svg' })} />
                }
                <ConfirmableLabel 
                    text={this.props.filename}
                    isRemoved = {this.props.isRemoved}      
                />
                {
                    this.props.dlp?.status === DlpItemStatus.Unsupported &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="Info" className={classNames.info} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.Scanning && !this.props.scanTimedOut &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <Spinner type={SpinnerType.normal} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.Scanning && this.props.scanTimedOut &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="Warning" className={classNames.warning} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.Error &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="FileBug" className={classNames.error} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.PasswordProtected &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="ProtectRestrict" className={classNames.info} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.ProtectedDocument &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="ProtectedDocument" className={classNames.info} />
                    </TooltipHost>                                                    
                }
                {
                    this.props.dlp?.status === DlpItemStatus.SensitiveDataFound &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="ReportWarning" className={classNames.error} />
                    </TooltipHost>
                }
                {
                    this.props.dlp?.status === DlpItemStatus.Success &&
                    <TooltipHost
                        content={this.props.dlp.statusString}>
                        <FontIcon iconName="Completed" className={classNames.completed} />
                    </TooltipHost>
                }
                {
                    this.state.fileSize?.size &&
                    <div className={classNames.fileSizeContainer}>
                        <Label>{this.state.fileSize.size} {this.state.fileSize.unitType}</Label>
                    </div>
                }
                {  
                    this.shouldRenderRemovableIcons()
                    ? (
                        <div className={classNames.removableIconContainer}>
                            {
                                this.props.isRemoved
                                ? <IconButton iconProps={this.AddIcon} title='Add' ariaLabel='Add' onClick={this.handleRemove}></IconButton>
                                : <IconButton iconProps={this.removeIcon} title='Remove' ariaLabel='Remove' onClick={this.handleRemove}></IconButton>
                            }
                        </div>
                    )
                    : null
                }  
            </Stack>
        );
    }
}