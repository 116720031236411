import * as React from 'react';
import { mergeStyleSets, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';

const classNames = mergeStyleSets({
    mainSpinner: {
        width: '100px',
        height: '100px'
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
});

export interface OutlookSpinnerProps {
    message: string
}

export default class OutlookSpinner extends React.Component<OutlookSpinnerProps> {
    render() {
        return (
            <>
                <Stack className={classNames.box}>
                    <div className="ms-BrandIcon--icon48 ms-BrandIcon--outlook"></div>
					<Spinner size={SpinnerSize.large} className={classNames.mainSpinner} />
					<Text variant={'medium'} block>{this.props.message}</Text>
                </Stack>
            </>
        );
    }
}