import { Separator } from "@fluentui/react";
import * as React from "react";

export interface WarningMessageProps {
    message: string;
}

export default class WarningMessage extends React.Component<WarningMessageProps> {
    render() {
        const { message } = this.props;

        return (
            <>
                <Separator style={{ backgroundColor: 'd83b01' }} alignContent="start" className="ms-fontSize-s ms-fontWeight-light ">{message}</Separator>
            </>
        );
    }
}