import { Modules } from '../LogConfiguration';
import { safeSendLogging } from './Logger';
const logger = safeSendLogging.getLogger(Modules.SafeSend_AddIn);

var prefix = "SafeSend:";

export class NotificationLogger {
	public infoReplace(mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined, id: string, message: string): void {
		logger.debug(message);
		mailboxItem?.notificationMessages.replaceAsync(id, this.getJsonMessage(Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage, message));
	}

	public errorReplace(mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined, id: string, message: string): void {
		logger.debug(message);
		mailboxItem?.notificationMessages.replaceAsync(id, this.getJsonMessage(Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage, message));
	}

	public progressReplace(mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined, id: string, message: string): void {
		logger.debug(message);
		mailboxItem?.notificationMessages.replaceAsync(id, this.getJsonMessage(Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator, message));
	}

	public remove(mailboxItem: (Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead) | undefined, id: string): void {
		mailboxItem?.notificationMessages.removeAsync(id);
	}

	private getJsonMessage(type: Office.MailboxEnums.ItemNotificationMessageType, message: string): Office.NotificationMessageDetails {
		if (type === Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage) {
			return { type: type, message: `${prefix} ${message}`, icon: "iconid", persistent: false }
		}

		return { type: type, message: `${prefix} ${message}` }
	}
}

export const notificationLogger = new NotificationLogger();