import { Checkbox, IconButton, IIconProps, IStackTokens, Label, SharedColors, Stack } from '@fluentui/react';
import * as React from 'react';
import PersonaConfirmable from '../Persona/PersonaConfirmable';
import { IGroupRecipientsProps, IGroupRecipientsStates } from "./GroupRecipientsTypes";
import ConfirmableLabel from '../Shared/ConfirmableLabel';

const stackTokensPersonaLeft: IStackTokens = {
    padding: `${0}px ${0}px ${0}px ${54}px`,
};

const stackTokensPersonaRigth: IStackTokens = {
    padding: `${0}px ${47}px ${0}px ${0}px`,
};

export default class GroupRecipients extends React.Component<IGroupRecipientsProps, IGroupRecipientsStates> {
    constructor(props) {
        super(props);
        this.state = {
            stateUpdate: false,
            checked: false,
            toggle: props.expanded
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChangePersonaConfirmable = this.handleChangePersonaConfirmable.bind(this);
        this.handleChangeRemoveGroupConfirmable = this.handleChangeRemoveGroupConfirmable.bind(this);
    }

    static getDerivedStateFromProps(nextProps: IGroupRecipientsProps, prevState: IGroupRecipientsStates) {
        if (prevState.stateUpdate) {
            return ({ stateUpdate: false })
        }

        if (nextProps.checked !== prevState.checked) {
            return ({ checked: nextProps.checked })
        }

        if (nextProps.isRemoved) {
            return ({ toggle: false })
        }

        return null
    }

    handleToggle() {
        this.setState({
            toggle: !this.state.toggle
        });
    }

    handleChange(e) {
        let checked = (e?.target as HTMLInputElement).checked;
        this.setState({
            checked: checked,
            stateUpdate: true
        });
        this.props.onChange(this.props.id, checked);
    }

    handleChangePersonaConfirmable(id: string, checked: boolean) {
        this.setState({
            checked: checked,
            stateUpdate: true
        });
        this.props.onChange(id, checked);
    }

    handleChangeRemoveGroupConfirmable() {
        this.props.handleRecipientGroupRemoved(this.props.text);
        this.props.onChange(this.props.id, this.state.checked);
    }

    shouldRenderRemovableIcons(){
        if (!this.props.isRecipientAttachmentRemovalEnabled){
            return false;
        }

        return true;
    }

    removeIcon: IIconProps = { iconName: "Cancel", styles:{root: {color: SharedColors.red20}}};
    AddIcon: IIconProps = { iconName: "Add", styles: {root: {color: SharedColors.blue10}}};

    render() {
        return (
            <>
                { this.props.single &&
                    <PersonaConfirmable
                        onChange={this.handleChangePersonaConfirmable}
                        disabled={this.props.disabled}
                        checked={this.state.checked}
                        key={this.props.id}
                        id={this.props.id}
                        showCheckBox={true}
                        text={this.props.text}
                        colorDomains={this.props.colorDomains}
                        color={this.props.color}
                        alignRightToLeft={this.props.alignRightToLeft}
                        isRemoved = {this.props.isRemoved} 
                        handleRemoved={this.props.handlePersonaRemoved}
                        recipientType={this.props.recipientType}
                        isRecipientRemovalDisabled={this.props.isRecipientRemovalDisabled}
                        isRecipientAttachmentRemovalEnabled={this.props.isRecipientAttachmentRemovalEnabled}>
                    </PersonaConfirmable>
                }
                { !this.props.single &&
                    <Stack>
                        <Stack reversed={this.props.alignRightToLeft} horizontal wrap>
                            <Stack.Item align="center">
                                <Checkbox disabled={this.props.disabled || this.props.isRemoved} onChange={this.handleChange} checked={this.state.checked} />
                            </Stack.Item>
                            <Stack.Item align="center">
                                {
                                    !this.state.toggle && <IconButton disabled= {this.props.isRemoved} onClick={this.handleToggle} iconProps={{ iconName: 'ChevronDown' }} title="" ariaLabel="Down" />
                                }
                                {
                                    this.state.toggle && <IconButton onClick={this.handleToggle} iconProps={{ iconName: 'ChevronUp' }} title="" ariaLabel="Up" />
                                }
                            </Stack.Item>
                            <Stack.Item align="center">
                                <ConfirmableLabel 
                                    isRemoved={this.props.isRemoved}
                                    colorDomains={this.props.colorDomains}
									color={this.props.color}
									text={`${this.props.text === "Unresolvable" ? "" : "@"}${this.props.text}`}
                                />
                            </Stack.Item>
                            {
                                this.shouldRenderRemovableIcons()
                                ? (
                                    this.props.isRemoved
                                    ? <IconButton disabled={this.props.isRecipientRemovalDisabled} iconProps={this.AddIcon} title='Add' ariaLabel='Add' onClick={this.handleChangeRemoveGroupConfirmable}></IconButton>
                                    : <IconButton disabled={this.props.isRecipientRemovalDisabled} iconProps={this.removeIcon} title='Remove' ariaLabel='Remove' onClick={this.handleChangeRemoveGroupConfirmable}></IconButton>
                                ) 
                                : null 
                            }
                        </Stack>
                        {
                            this.state.toggle &&
                            <Stack horizontal reversed={this.props.alignRightToLeft} wrap tokens={this.props.alignRightToLeft ? stackTokensPersonaRigth : stackTokensPersonaLeft}>
                                <Stack>
                                {
                                    this.props.colorDomains &&
                                    this.props.recipients.map(x => <Label key={x.id} styles={{ root: { color: this.props.color } }}> { x.displayName } </Label>)
                                }
                                {
                                    !this.props.colorDomains &&
                                    this.props.recipients.map(x => <Label key={x.id}> { x.displayName } </Label>)
                                }
                                </Stack>
                            </Stack>
                        }
                    </Stack>
                }
            </>            
        );
    }
}